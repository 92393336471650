import { useEffect, useMemo, useState, useRef, Fragment } from "react";
import { useTable } from "react-table/dist/react-table.development";
import useToken from "../App/useToken";
import { ReactSVG } from "react-svg";
import { IoChevronDownOutline, IoCheckmarkDoneOutline, IoCloseOutline, IoPrintOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import axios from "axios";
import dateFormat from "dateformat";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReimbursementPrint from "./ReimbursementPrint";

const getReimbursement = ({ page, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, userID }) => {
    const statusMap = {
        draft: "Draft",
        posted: "Posted",
        submitted: "Submitted",
    };

    const getTotal = (entries) => {
        let total = 0;

        if (Array.isArray(entries)) {
            entries.forEach((entry) => {
                total += entry.meal + entry.transport + entry.fee;
            });
        }

        return currencyFormatter.format(total);
    };

    axios
        .get(process.env.REACT_APP_API_URL + "/reimbursement/user/" + userID + "?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];

                response.data.data.forEach((prj) => {
                    tableData.push({
                        col1: prj.number,
                        col2: <span className={"status-" + prj.status}>{statusMap[prj.status]}</span>,
                        col3: dateFormat(prj.date, "mmmm yyyy"),
                        col4: getTotal(prj.entries),
                        col5: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        data: prj,
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setReimbursement([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getReimbursement, props: { page, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, userID } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const getReimbursementEntry = ({ reimbursementID, token, setReimbursementEntry, setLoading, setDataEdit, refreshToken, project }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/reimbursement/" + reimbursementID + "/entry", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && Array.isArray(response.data)) {
                const tableData = [];

                let mealTotal = 0;
                let transportTotal = 0;
                let feeTotal = 0;

                response.data.forEach((prj, index) => {
                    const projectOption = project.find((r) => r.id === prj.project_id);

                    mealTotal += prj.meal;
                    transportTotal += prj.transport;
                    feeTotal += prj.fee;

                    tableData.push({
                        col1: index + 1,
                        col2: dateFormat(prj.date, "dd/mm/yyyy"),
                        col3: projectOption ? projectOption.code + " - " + projectOption.name : "",
                        col4: currencyFormatter.format(prj.transport),
                        col5: currencyFormatter.format(prj.meal),
                        col6: currencyFormatter.format(prj.fee),
                        col7: currencyFormatter.format(prj.transport + prj.meal + prj.fee),
                        col8: prj.remark,
                        data: prj,
                    });
                });

                tableData.push({
                    col3: "Grand Total",
                    col4: currencyFormatter.format(transportTotal),
                    col5: currencyFormatter.format(mealTotal),
                    col6: currencyFormatter.format(feeTotal),
                    col7: currencyFormatter.format(transportTotal + mealTotal + feeTotal),
                });

                setReimbursementEntry([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getReimbursementEntry, props: { reimbursementID, token, setReimbursementEntry, setLoading, setDataEdit, project } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const getProject = ({ token, setProject, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/project/ongoing", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setProject([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getProject, props: { token, setProject } });
            } else {
                console.log(error);
            }
        });
};

const getUser = ({ token, setUser, userID, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/user/" + userID, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setUser({ ...response.data.data });
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getUser, props: { token, userID } });
            } else {
                console.log(error);
            }
        });
};

const postedReimbursement = ({ token, setIsLoadingPosted, notify, refreshToken, dataEdit, setDataEdit, user }) => {
    axios
        .post(
            process.env.REACT_APP_API_URL + "/reimbursement/" + dataEdit.id + "/posted",
            { sallary: user.sallary },
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token.token}`,
                },
            }
        )
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                notify("success", "Success Approve Reimbursement");
                setDataEdit({ ...dataEdit, status: "posted" });
            } else {
                notify("error", "Failed Approve Reimbursement");
            }

            setIsLoadingPosted(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: postedReimbursement, props: { token, setIsLoadingPosted, notify, dataEdit, setDataEdit, user } });
            } else {
                notify("error", "Failed Approve Reimbursement");
                setIsLoadingPosted(false);
                console.log(error);
            }
        });
};

const unpostedReimbursement = ({ token, setIsLoadingUnposted, notify, refreshToken, dataEdit, setDataEdit }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/reimbursement/" + dataEdit.id + "/unposted", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                notify("success", "Success Unapprove Reimbursement");
                setDataEdit({ ...dataEdit, status: "submitted" });
            } else {
                notify("error", "Failed Unapprove Reimbursement");
            }

            setIsLoadingUnposted(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: postedReimbursement, props: { token, setIsLoadingUnposted, notify, dataEdit, setDataEdit } });
            } else {
                notify("error", "Failed Unapprove Reimbursement");
                setIsLoadingUnposted(false);
                console.log(error);
            }
        });
};

const currencyFormatter = new Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
});

const EmployeeReimbursement = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [reimbursement, setReimbursement] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const [project, setProject] = useState([]);
    const { token, refreshToken } = useToken();
    const [user, setUser] = useState({});
    const { id } = useParams();

    const data = useMemo(() => reimbursement, [reimbursement]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Status",
                accessor: "col2",
            },
            {
                Header: "Month",
                accessor: "col3",
            },
            {
                Header: "Total",
                accessor: "col4",
            },
            {
                Header: "",
                accessor: "col5",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getReimbursement({ page: 1, userID: id, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken });
        getProject({ token, setProject, refreshToken });
        getUser({ token, refreshToken, setUser, userID: id });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getReimbursement({ page, userID: id, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken });
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                {dataEdit ? (
                    <ReimbursementPeriod user={user} dataEdit={dataEdit} setDataEdit={setDataEdit} project={project} token={token} refreshToken={refreshToken} notify={notify}></ReimbursementPeriod>
                ) : (
                    <Fragment>
                        <div className="content-title">
                            <span>{user.name}'s Reimbursement</span>
                        </div>
                        <div className="content-table table-reimbursement">
                            {!loading && (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps()} key={index}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={index} onClick={() => setDataEdit(row.original.data)}>
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <td {...cell.getCellProps()} key={index}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="content-pagination">
                            <span className="page-information">
                                Page {currentPage} of {lastPage}
                            </span>
                            <div className="page-actions">
                                {currentPage > 1 && (
                                    <Fragment>
                                        <div className="select-first" onClick={() => handleGoToPage(1)}>
                                            <BiChevronsLeft />
                                        </div>
                                        <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                            <BiChevronLeft />
                                        </div>
                                    </Fragment>
                                )}
                                {lastPage > 1 && (
                                    <div className="select-page">
                                        <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                                    </div>
                                )}
                                {currentPage < lastPage && (
                                    <Fragment>
                                        <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                            <BiChevronRight />
                                        </div>
                                        <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                            <BiChevronsRight />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </section>
        </NavigationFull>
    );
};

const ReimbursementPeriod = ({ user, dataEdit, setDataEdit, project, token, refreshToken, notify }) => {
    const [loading, setLoading] = useState(true);
    const [reimbursementEntry, setReimbursementEntry] = useState([]);
    const [isLoadingPosted, setIsLoadingPosted] = useState(false);
    const [isLoadingUnposted, setIsLoadingUnposted] = useState(false);
    const [postedConfirmation, setPostedConfirmation] = useState(false);
    const [unpostedConfirmation, setUnpostedConfirmation] = useState(false);

    let printComponentRef = useRef();

    const data = useMemo(() => reimbursementEntry, [reimbursementEntry]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Date",
                accessor: "col2",
            },
            {
                Header: "Project",
                accessor: "col3",
            },
            {
                Header: "Transport",
                accessor: "col4",
            },
            {
                Header: "Meal",
                accessor: "col5",
            },
            {
                Header: "Fee",
                accessor: "col6",
            },
            {
                Header: "Total",
                accessor: "col7",
            },
            {
                Header: "Remark",
                accessor: "col8",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getReimbursementEntry({ reimbursementID: dataEdit.id, token, project, setReimbursementEntry, setLoading, setDataEdit, refreshToken });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseButton = () => {
        handleCloseRedirect();
    };

    const handleCloseRedirect = () => {
        window.location.href = process.env.REACT_APP_HOME_URL + "/employee/" + user.id + "/reimbursement";
    };

    const handlePostedButton = () => {
        if (dataEdit.status !== "posted") {
            setPostedConfirmation(true);
        }
    };

    const handlePosted = () => {
        if (dataEdit.status !== "posted") {
            setIsLoadingPosted(true);
            postedReimbursement({ token, setIsLoadingPosted, notify, refreshToken, dataEdit, setDataEdit, user });
            setPostedConfirmation(false);
        }
    };

    const handleUnpostedButton = () => {
        if (dataEdit.status === "posted") {
            setUnpostedConfirmation(true);
        }
    };

    const handleUnposted = () => {
        if (dataEdit.status === "posted") {
            setIsLoadingUnposted(true);
            unpostedReimbursement({ token, setIsLoadingUnposted, notify, refreshToken, dataEdit, setDataEdit });
            setUnpostedConfirmation(false);
        }
    };

    return (
        <Fragment>
            <div className="content-title">
                <span>
                    <span className="content-title-main">{user.name}'s Reimbursement Entries</span>
                    <span className="content-title-sub">{dateFormat(dataEdit.date, "mmmm yyyy")}</span>
                </span>
                <div className="content-actions">
                    <button className="content-action-back" onClick={() => handleCloseButton()}>
                        <IoCloseOutline /> Close
                    </button>
                    <button className={dataEdit.status === "posted" ? "content-action-approve posted" : "content-action-approve"} onClick={handlePostedButton}>
                        {isLoadingPosted ? (
                            <ReactSVG className="loading-svg" src="loading.svg" />
                        ) : (
                            <Fragment>
                                <IoCheckmarkDoneOutline /> Approve
                            </Fragment>
                        )}
                    </button>
                    {dataEdit.status === "posted" && (
                        <button className="content-action-approve" onClick={handleUnpostedButton}>
                            {isLoadingUnposted ? (
                                <ReactSVG className="loading-svg" src="loading.svg" />
                            ) : (
                                <Fragment>
                                    <IoCloseOutline /> Unapprove
                                </Fragment>
                            )}
                        </button>
                    )}
                    <ReactToPrint
                        trigger={() => (
                            <button className="content-action-print">
                                <IoPrintOutline /> Print
                            </button>
                        )}
                        content={() => printComponentRef}
                    />
                </div>
            </div>
            <div className="content-table table-reimbursement-entry">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {postedConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setPostedConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handlePosted();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Approve reimbursement?</p>
                </PopupConfirmation>
            )}
            {unpostedConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setUnpostedConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleUnposted();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Unapprove reimbursement?</p>
                </PopupConfirmation>
            )}
            <ReimbursementPrint printRef={(el) => (printComponentRef = el)} user={user} dataEdit={dataEdit} columns={columns} data={data} />
        </Fragment>
    );
};

export default EmployeeReimbursement;
