import UserInfo from "./UserInfo";

const Header = () => {

    return (
        <header className="dashboard-header">
            <div className="header-wrapper">
                <span></span>
                <UserInfo />
            </div>
        </header>
    );
};

export default Header;
