import { Fragment } from "react";
import useDate from "../App/useDate";

const TimesheetPrint = ({ user, printRef, sallary, dataEdit, allEntries, project, task, milestone }) => {
    let startEndDate = [];
    let totalHours = 0;

    const { getDates, formatDate } = useDate();

    const countWeek = () => {
        const listDates = getDates(dataEdit.start_date, dataEdit.end_date);

        let week = 0;
        let startWeek = formatDate(listDates[0]);
        let endWeek = formatDate(listDates[0]);

        startEndDate = [];

        listDates.forEach((date, index) => {
            const d = new Date(date);

            if (d.getDay() === 1 && index !== 0) {
                startEndDate.push({
                    start: formatDate(startWeek),
                    end: formatDate(endWeek),
                });

                startWeek = formatDate(date);
                endWeek = formatDate(date);

                week++;
            }

            endWeek = date;
        });

        startEndDate.push({
            start: formatDate(startWeek),
            end: formatDate(endWeek),
        });

        week++;

        return week;
    };

    const getProjectSummary = () => {
        const summary = {};

        // console.log(allEntries);

        allEntries.forEach((entry) => {
            if (!summary.hasOwnProperty(entry.project_id)) {
                const projectOption = project.find((r) => r.id === entry.project_id);

                summary[entry.project_id] = {
                    project_name: projectOption ? projectOption.code + " - " + projectOption.name : "",
                    weeks: {},
                };
            }

            if (!summary[entry.project_id]["weeks"].hasOwnProperty(entry.week)) {
                summary[entry.project_id]["weeks"][entry.week] = 0;
            }

            entry.dates.forEach((date) => {
                summary[entry.project_id]["weeks"][entry.week] += date.hour ? date.hour : 0;
            });
        });

        return summary;
    };

    const tableHead = () => {
        return (
            <thead>
                <tr>
                    <th>Project Name</th>
                    <th>Milestone / Phase</th>
                    <th>Task</th>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                    <th>Sun</th>
                    <th>Hours</th>
                </tr>
            </thead>
        );
    };

    const tableWeek = (week) => {
        let totalPeriodHours = 0;
        const weekEntries = allEntries.filter((r) => r.status !== "new" && r.week === week);
        const weekHours = {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        };

        const getDayHour = (day, dates) => {
            let dateHour = 0;
            const date = dates.find((r) => r.day === day);

            dateHour = date ? date.hour : 0;
            weekHours[day] += dateHour;
            totalPeriodHours += dateHour;
            totalHours += dateHour;

            return dateHour;
        };

        return (
            <tbody>
                {weekEntries.map((entry) => {
                    const projectOption = project.find((r) => r.id === entry.project_id);
                    const milestoneOption = milestone.find((r) => r.id === entry.milestone_id);
                    const taskOption = task.find((r) => r.id === entry.task_id);
                    const dates = entry.dates;

                    const countHours = () => {
                        let hours = 0;

                        dates.forEach((date) => {
                            hours += date.hour;
                        });

                        return hours;
                    };

                    return (
                        <tr key={entry.id}>
                            <td>{projectOption ? projectOption.code + " - " + projectOption.name : ""}</td>
                            <td>{milestoneOption ? milestoneOption.code + " - " + milestoneOption.name : ""}</td>
                            <td>{taskOption ? taskOption.code + " - " + taskOption.name : ""}</td>

                            <td>{getDayHour("monday", dates)}</td>
                            <td>{getDayHour("tuesday", dates)}</td>
                            <td>{getDayHour("wednesday", dates)}</td>
                            <td>{getDayHour("thursday", dates)}</td>
                            <td>{getDayHour("friday", dates)}</td>
                            <td>{getDayHour("saturday", dates)}</td>
                            <td>{getDayHour("sunday", dates)}</td>
                            <td>{countHours()}</td>
                        </tr>
                    );
                })}
                <tr>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>{Math.round(weekHours["monday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["tuesday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["wednesday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["thursday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["friday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["saturday"] * 100) / 100}</td>
                    <td>{Math.round(weekHours["sunday"] * 100) / 100}</td>
                    <td>{Math.round(totalPeriodHours * 100) / 100}</td>
                </tr>
            </tbody>
        );
    };

    const getNote = () => {
        const notes = [];

        allEntries
            .sort((a, b) => a.week - b.week)
            .map((entry) => {
                if (Array.isArray(entry.dates)) {
                    entry.dates.forEach((date) => {
                        if (date.note) {
                            const startDate = startEndDate[entry.week - 1] ? startEndDate[entry.week - 1].start : "";
                            const endDate = startEndDate[entry.week - 1] ? startEndDate[entry.week - 1].end : "";
                            const projectOption = project.find((r) => r.id === entry.project_id);
                            const milestoneOption = milestone.find((r) => r.id === entry.milestone_id);
                            const taskOption = task.find((r) => r.id === entry.task_id);

                            notes.push(
                                <tr key={date.id}>
                                    <td>{entry.week}</td>
                                    <td>{startDate + " - " + endDate}</td>
                                    <td>{projectOption ? projectOption.code + " - " + projectOption.name : ""}</td>
                                    <td>{milestoneOption ? milestoneOption.code + " - " + milestoneOption.name : ""}</td>
                                    <td>{taskOption ? taskOption.code + " - " + taskOption.name : ""}</td>
                                    <td>{date.note}</td>
                                </tr>
                            );
                        }
                    });
                }
            });

        return notes;
    };

    const tablePeriod = () => {
        const weekObj = [];
        const projectSummary = getProjectSummary();

        for (let i = 0; i < countWeek(); i++) {
            weekObj.push(i + 1);
        }

        return (
            <Fragment>
                {weekObj.map((week, index) => (
                    <Fragment key={week}>
                        <span className="content-print-table-period">
                            {startEndDate[index]["start"]} - {startEndDate[index]["end"]}
                        </span>
                        <table className="content-print-table table-period">
                            {tableHead()}
                            {tableWeek(week)}
                        </table>
                    </Fragment>
                ))}
                <div className="content-print-total-hours">Total Hours: {Math.round(totalHours * 100) / 100}</div>
                <div className="content-print-summary">Summary</div>
                <table className="content-print-table table-summary">
                    <thead>
                        <tr>
                            <th>Project</th>
                            {weekObj.map((week) => (
                                <th key={week}>Week {week}</th>
                            ))}
                            <th>Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(projectSummary).map((projectID) => {
                            let projectHours = 0;

                            return (
                                <tr key={projectID}>
                                    <td>{projectSummary[projectID].project_name}</td>
                                    {weekObj.map((week) => {
                                        let hours = projectSummary[projectID].weeks.hasOwnProperty(week) ? projectSummary[projectID].weeks[week] : 0;
                                        projectHours += hours;

                                        return <td key={week}>{Math.round(hours * 100) / 100}</td>;
                                    })}
                                    <td>{Math.round(projectHours * 100) / 100}</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td>Total</td>
                            {weekObj.map((week) => {
                                let weekHour = 0;

                                Object.keys(projectSummary).forEach((projectID) => {
                                    weekHour += projectSummary[projectID].weeks.hasOwnProperty(week) ? projectSummary[projectID].weeks[week] : 0;
                                });

                                return <td key={week}>{Math.round(weekHour * 100) / 100}</td>;
                            })}
                            <td>{Math.round(totalHours * 100) / 100}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="content-print-note">Note</div>
                <table className="content-print-table table-note">
                    <thead>
                        <tr>
                            <th>Week</th>
                            <th>Date</th>
                            <th>Project</th>
                            <th>Milestone / Phase</th>
                            <th>Task</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getNote().map((note) => {
                            return note;
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    };

    return (
        <div className="content-print" ref={printRef}>
            <div className="content-print-header">
                <div className="content-print-header-user">
                    <table className="content-print-table table-user">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Employee Name</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {user.name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Approval Status</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {dataEdit.status}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Employee ID</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {user.code}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Timesheet Number</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {dataEdit.number}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Period Date Range</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {formatDate(dataEdit.start_date)} - {formatDate(dataEdit.end_date)}
                                </td>
                            </tr>
                            {sallary && (
                                <tr>
                                    <td>
                                        <strong>Sallary</strong>
                                    </td>
                                    <td>
                                        <strong> : </strong> {sallary}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="content-print-header-user">
                    <img src={process.env.REACT_APP_HOME_URL + "/logo.png"} alt="ucds logo" width="300" className="dashboard-logo" />
                </div>
            </div>
            <div className="content-print-body">{tablePeriod()}</div>
            <div className="content-print-signature">
                <div className="content-print-signature-employee">
                    <div className="signature-box"></div>
                    <span className="signature-name">Employee Signature</span>
                </div>
                <div className="content-print-signature-manager">
                    <div className="signature-box"></div>
                    <span className="signature-name">Manager Signature</span>
                </div>
            </div>
        </div>
    );
};

export default TimesheetPrint;
