import { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import HeaderFull from "./HeaderFull";

const NavigationFull = ({children}) => {
    const [sidebarActive, setSidebarActive] = useState(false);
    const sidebarButtonRef = useRef();
    const sidebarRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickSidebarOutside);
        return () => document.removeEventListener("mousedown", handleClickSidebarOutside);
    });

    const handleSidebar = () => {
        setSidebarActive(!sidebarActive);
    };

    const handleClickSidebarOutside = (e) => {
        if (!sidebarRef.current.contains(e.target) && !sidebarButtonRef.current.contains(e.target)) {
            setSidebarActive(false);
        }
    };

    return (
        <div className="dashboard-wrapper">
            <Sidebar sidebarActive={sidebarActive} sidebarRef={sidebarRef} />
            <div className="dashboard-content">
                <HeaderFull handleSidebar={handleSidebar} sidebarButtonRef={sidebarButtonRef} />
                {children}
            </div>
        </div>
    );
};

export default NavigationFull;
