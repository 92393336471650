import { useTable } from "react-table/dist/react-table.development";
import dateFormat from "dateformat";

const ReimbursementPrint = ({ user, dataEdit, printRef, columns, data }) => {
    if (columns.length > 8) {
        columns.splice(-1, 1);
    }

    if (data.length > 8) {
        data.splice(-1, 1);
    }

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <div className="content-print" ref={printRef}>
            <div className="content-print-header">
                <div className="content-print-header-user">
                    <table className="content-print-table table-user">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Employee Name</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {user.name}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Approval Status</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {dataEdit.status}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Employee ID</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {user.code}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Period</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {dateFormat(dataEdit.date, "mmmm yyyy")}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Reimbursement Number</strong>
                                </td>
                                <td>
                                    <strong> : </strong> {dataEdit.number}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="content-print-header-user">
                    <img src={process.env.REACT_APP_HOME_URL + "/logo.png"} alt="ucds logo" width="300" className="dashboard-logo" />
                </div>
            </div>
            <div className="content-print-body">
                <table className="content-print-table table-reimbursement-entry" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, index) => (
                                    <th {...column.getHeaderProps()} key={index}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td {...cell.getCellProps()} key={index}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="content-print-signature">
                <div className="content-print-signature-employee">
                    <div className="signature-box"></div>
                    <span className="signature-name">Employee Signature</span>
                </div>
                <div className="content-print-signature-manager">
                    <div className="signature-box"></div>
                    <span className="signature-name">Manager Signature</span>
                </div>
            </div>
        </div>
    );
};

export default ReimbursementPrint;
