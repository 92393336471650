import { useState } from "react";
import axios from "axios";

const useToken = () => {
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem("token", JSON.stringify(userToken));
        setToken(userToken);
    };

    const refreshToken = ({ callback, props }) => {
        axios
            .post(process.env.REACT_APP_API_URL + "/auth/refresh", null, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token.token}`,
                },
            })
            .then((response) => {
                if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                    saveToken(response.data);
                }

                callback({ ...props, token: getToken() });
            })
            .catch((error) => {
                console.log(error);
                callback({ ...props, token: getToken() });
            });
    };

    return {
        setToken: saveToken,
        token,
        refreshToken,
    };
};

export default useToken;
