const useString = () => {
    const getAllStrings = (arg) => {
        if (typeof arg === "string") {
            return [arg];
        }

        // handle wrong types and null
        if (typeof arg !== "object" || !arg) {
            return [];
        }

        return Object.keys(arg).reduce((acc, key) => {
            return [...acc, ...getAllStrings(arg[key])];
        }, []);
    };

    return {
        getAllStrings,
    };
};

export default useString;
