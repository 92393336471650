import { IoCloseOutline } from "react-icons/io5";

const PopupConfirmation = ({cancelAction, footerAction, children}) => {
    return (
        <div className="content-popup">
            <div className="content-popup-overlay"></div>
            <div className="content-popup-container">
                <div className="content-popup-header">
                    <span className="content-popup-title">Notice</span>
                    <IoCloseOutline onClick={cancelAction} />
                </div>
                <div className="content-popup-body">
                    {children}
                </div>
                <div className="content-popup-footer">
                    {footerAction}
                </div>
            </div>
        </div>
    );
};

export default PopupConfirmation;
