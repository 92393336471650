import { useEffect, useMemo, useState, useRef, Fragment } from "react";
import { useTable } from "react-table/dist/react-table.development";
import useToken from "../App/useToken";
import useUser from "../App/useUser";
import { ReactSVG } from "react-svg";
import { IoAddOutline, IoChevronDownOutline, IoTrashOutline, IoCloudUploadOutline, IoCloseOutline, IoSaveOutline, IoPrintOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import ReactToPrint from "react-to-print";
import ReimbursementPrint from "./ReimbursementPrint";
import DatePicker from "react-datepicker";
import axios from "axios";
import dateFormat from "dateformat";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";

const getReimbursement = ({ page, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete }) => {
    const today = new Date();
    const statusMap = {
        draft: "Draft",
        posted: "Posted",
        submitted: "Submitted",
    };

    const getTotal = (entries) => {
        let total = 0;

        if (Array.isArray(entries)) {
            entries.forEach((entry) => {
                total += entry.meal + entry.transport + entry.fee;
            });
        }

        return currencyFormatter.format(total);
    };

    axios
        .get(process.env.REACT_APP_API_URL + "/reimbursement?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];

                response.data.data.forEach((prj) => {
                    const endMonth = new Date(prj.date);

                    tableData.push({
                        col1: prj.number,
                        col2: <span className={"status-" + prj.status}>{statusMap[prj.status]}</span>,
                        col3: dateFormat(prj.date, "mmmm yyyy"),
                        col4: getTotal(prj.entries),
                        col5: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        col6: prj.status === "draft" && endMonth > today ? <IoTrashOutline onClick={(e) => handleDelete(e, prj)}></IoTrashOutline> : <div></div>,
                        data: prj,
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setReimbursement([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getReimbursement, props: { page, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, handleDelete } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const getReimbursementEntry = ({ dataEdit, token, setReimbursementEntry, setLoading, setDataEdit, refreshToken, handleDelete, project }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/reimbursement/" + dataEdit.id + "/entry", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && Array.isArray(response.data)) {
                const tableData = [];

                let mealTotal = 0;
                let transportTotal = 0;
                let feeTotal = 0;

                response.data.forEach((prj, index) => {
                    const projectOption = project.find((r) => r.id === prj.project_id);

                    mealTotal += prj.meal;
                    transportTotal += prj.transport;
                    feeTotal += prj.fee;

                    tableData.push({
                        col1: index + 1,
                        col2: dateFormat(prj.date, "dd/mm/yyyy"),
                        col3: projectOption ? projectOption.code + " - " + projectOption.name : "",
                        col4: currencyFormatter.format(prj.transport),
                        col5: currencyFormatter.format(prj.meal),
                        col6: currencyFormatter.format(prj.fee),
                        col7: currencyFormatter.format(prj.transport + prj.meal + prj.fee),
                        col8: prj.remark,
                        col9: dataEdit.status !== "posted" ? <IoTrashOutline onClick={(e) => handleDelete(e, prj)}></IoTrashOutline> : <div></div>,
                        data: prj,
                    });
                });

                tableData.push({
                    col3: "Grand Total",
                    col4: currencyFormatter.format(transportTotal),
                    col5: currencyFormatter.format(mealTotal),
                    col6: currencyFormatter.format(feeTotal),
                    col7: currencyFormatter.format(transportTotal + mealTotal + feeTotal),
                });

                setReimbursementEntry([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getReimbursementEntry, props: { dataEdit, token, setReimbursementEntry, setLoading, setDataEdit, handleDelete, project } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const addReimbursement = ({ token, handleGoToPage, setNewLoading, refreshToken }) => {
    setNewLoading(true);

    axios
        .post(process.env.REACT_APP_API_URL + "/reimbursement", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(1);
            }

            setNewLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: addReimbursement, props: { token, handleGoToPage, setNewLoading } });
            } else {
                setNewLoading(false);
                console.log(error);
            }
        });
};

const getProject = ({ token, setProject, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/project/ongoing", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setProject([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getProject, props: { token, setProject } });
            } else {
                console.log(error);
            }
        });
};

const deleteReimbursement = ({ currentPage, handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation }) => {
    axios
        .delete(process.env.REACT_APP_API_URL + "/reimbursement/" + dataDelete.id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && response.data) {
                handleGoToPage(currentPage);
            }

            setDeleteConfirmation(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: deleteReimbursement, props: { currentPage, handleGoToPage, token, dataDelete, setDeleteConfirmation } });
            }

            setDeleteConfirmation();
        });
};

const submitReimbursement = ({ dataEdit, setDataEdit, token, setIsLoadingSubmit, notify, refreshToken, setDataEditEntry }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/reimbursement/" + dataEdit.id + "/submit", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setDataEdit({ ...dataEdit, status: "submitted" });
                notify("success", "Success Submit Timesheet");
            } else {
                notify("error", "Failed Submit Reimbursement");
            }

            setIsLoadingSubmit(false);
            setDataEditEntry(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: submitReimbursement, props: { dataEdit, setDataEdit, token, setIsLoadingSubmit, notify, setDataEditEntry } });
            } else {
                notify("error", "Failed Submit Reimbursement");
                setDataEditEntry(false);
                setIsLoadingSubmit(false);
                console.log(error);
            }
        });
};

const addEntry = ({ handleGoToPage, notify, refreshToken, dataEditEntry, setDataEditEntry, token }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/reimbursement-entry", dataEditEntry, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage();
            }

            setDataEditEntry(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: addEntry, props: { handleGoToPage, notify, dataEditEntry, token, setDataEditEntry } });
            } else {
                setDataEditEntry(false);
                console.log(error);
            }
        });
};

const updateEntry = ({ handleGoToPage, notify, refreshToken, dataEditEntry, setDataEditEntry, token }) => {
    axios
        .put(process.env.REACT_APP_API_URL + "/reimbursement-entry/" + dataEditEntry.id, dataEditEntry, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage();
            }

            setDataEditEntry(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: updateEntry, props: { handleGoToPage, notify, dataEditEntry, token, setDataEditEntry } });
            } else {
                setDataEditEntry(false);
                console.log(error);
            }
        });
};

const deleteEntry = ({ handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation, setDataEditEntry }) => {
    axios
        .delete(process.env.REACT_APP_API_URL + "/reimbursement-entry/" + dataDelete.id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage();
            }

            setDataEditEntry(false);
            setDeleteConfirmation(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: deleteEntry, props: { handleGoToPage, token, dataDelete, setDeleteConfirmation, setDataEditEntry } });
            } else {
                setDataEditEntry(false);
                setDeleteConfirmation(false);
                console.log(error);
            }
        });
};

const currencyFormatter = new Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
});

const Reimbursement = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [newLoading, setNewLoading] = useState(false);
    const [reimbursement, setReimbursement] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const [project, setProject] = useState([]);
    const { token, refreshToken } = useToken();
    const { user } = useUser();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const data = useMemo(() => reimbursement, [reimbursement]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Status",
                accessor: "col2",
            },
            {
                Header: "Month",
                accessor: "col3",
            },
            {
                Header: "Total",
                accessor: "col4",
            },
            {
                Header: "",
                accessor: "col5",
            },
            {
                Header: "",
                accessor: "col6",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getReimbursement({ page: 1, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete });
        getProject({ token, setProject, refreshToken });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getReimbursement({ page, token, setReimbursement, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete });
    };

    const handleAddNew = () => {
        addReimbursement({ token, handleGoToPage, setNewLoading, refreshToken });
    };

    const handleDelete = (e, prj) => {
        e.preventDefault();
        e.stopPropagation();

        setDeleteConfirmation(prj);
    };

    const handleDeleteReimbursement = () => {
        const dataDelete = deleteConfirmation;
        deleteReimbursement({ currentPage, handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation });
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                {dataEdit ? (
                    <ReimbursementPeriod user={user} dataEdit={dataEdit} setDataEdit={setDataEdit} project={project} token={token} refreshToken={refreshToken} notify={notify}></ReimbursementPeriod>
                ) : (
                    <Fragment>
                        <div className="content-title">
                            <span>Reimbursement</span>
                        </div>
                        <div className="content-add-new">
                            <button className="add-new-reimbursent" onClick={handleAddNew}>
                                {newLoading ? (
                                    <ReactSVG src="loading.svg" />
                                ) : (
                                    <Fragment>
                                        <IoAddOutline></IoAddOutline>New Reimbursement
                                    </Fragment>
                                )}
                            </button>
                        </div>
                        <div className="content-table table-reimbursement">
                            {!loading && (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps()} key={index}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={index} onClick={() => setDataEdit(row.original.data)}>
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <td {...cell.getCellProps()} key={index}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="content-pagination">
                            <span className="page-information">
                                Page {currentPage} of {lastPage}
                            </span>
                            <div className="page-actions">
                                {currentPage > 1 && (
                                    <Fragment>
                                        <div className="select-first" onClick={() => handleGoToPage(1)}>
                                            <BiChevronsLeft />
                                        </div>
                                        <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                            <BiChevronLeft />
                                        </div>
                                    </Fragment>
                                )}
                                {lastPage > 1 && (
                                    <div className="select-page">
                                        <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                                    </div>
                                )}
                                {currentPage < lastPage && (
                                    <Fragment>
                                        <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                            <BiChevronRight />
                                        </div>
                                        <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                            <BiChevronsRight />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                        {deleteConfirmation && (
                            <PopupConfirmation
                                cancelAction={() => setDeleteConfirmation(false)}
                                footerAction={
                                    <button
                                        onClick={() => {
                                            handleDeleteReimbursement();
                                        }}
                                    >
                                        Continue
                                    </button>
                                }
                            >
                                <p className="">Delete Reimbursement?</p>
                            </PopupConfirmation>
                        )}
                    </Fragment>
                )}
            </section>
        </NavigationFull>
    );
};

const ReimbursementPeriod = ({ user, dataEdit, setDataEdit, project, token, refreshToken, notify }) => {
    const [loading, setLoading] = useState(true);
    const [dataEditEntry, setDataEditEntry] = useState(false);
    const [reimbursementEntry, setReimbursementEntry] = useState([]);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [postedConfirmation, setPostedConfirmation] = useState(false);
    const [submitConfirmation, setSubmitConfirmation] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    let printComponentRef = useRef();

    const optionProjectDefault = project.length > 0 ? project[0].id : "";

    const data = useMemo(() => reimbursementEntry, [reimbursementEntry]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Date",
                accessor: "col2",
            },
            {
                Header: "Project",
                accessor: "col3",
            },
            {
                Header: "Transport",
                accessor: "col4",
            },
            {
                Header: "Meal",
                accessor: "col5",
            },
            {
                Header: "Fee",
                accessor: "col6",
            },
            {
                Header: "Total",
                accessor: "col7",
            },
            {
                Header: "Remark",
                accessor: "col8",
            },
            {
                Header: "",
                accessor: "col9",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getReimbursementEntry({ dataEdit, token, project, setReimbursementEntry, setLoading, setDataEdit, refreshToken, handleDelete });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = () => {
        getReimbursementEntry({ dataEdit, token, project, setReimbursementEntry, setLoading, setDataEdit, refreshToken, handleDelete });
    };

    const handleCloseButton = () => {
        handleCloseRedirect();
    };

    const handleAddNew = () => {
        setDataEditEntry({ reimbursement_id: dataEdit.id, project_id: optionProjectDefault });
    };

    const handleSubmitButton = () => {
        if (dataEdit.status !== "submitted") {
            if (dataEdit.status === "posted") {
                setPostedConfirmation(true);
            } else {
                setSubmitConfirmation(true);
            }
        }
    };

    const handleSubmit = () => {
        if (dataEdit.status !== "submitted") {
            setIsLoadingSubmit(true);
            submitReimbursement({ dataEdit, setDataEdit, token, setIsLoadingSubmit, notify, refreshToken, setDataEditEntry });
            setSubmitConfirmation(false);
        }
    };

    const handleDelete = (e, prj) => {
        e.preventDefault();
        e.stopPropagation();

        setDeleteConfirmation(prj);
    };

    const handleCloseRedirect = () => {
        window.location.href = process.env.REACT_APP_HOME_URL + "/reimbursement";
    };

    const handleDeleteEntry = () => {
        const dataDelete = deleteConfirmation;
        deleteEntry({ handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation, setDataEditEntry });
    };

    return (
        <Fragment>
            <div className="content-title">
                <span>
                    <span className="content-title-main">Reimbursement Entries</span>
                    <span className="content-title-sub">{dateFormat(dataEdit.date, "mmmm yyyy")}</span>
                </span>
                <div className="content-actions">
                    <button className="content-action-back" onClick={() => handleCloseButton()}>
                        <IoCloseOutline /> Close
                    </button>
                    {dataEdit.status !== "posted" && (
                        <button className={dataEdit.status === "submitted" ? "content-action-submit submitted" : "content-action-submit"} onClick={handleSubmitButton}>
                            {isLoadingSubmit ? (
                                <ReactSVG className="loading-svg" src="loading.svg" />
                            ) : (
                                <Fragment>
                                    <IoCloudUploadOutline /> {dataEdit.status === "submitted" ? "Submitted" : "Submit"}
                                </Fragment>
                            )}
                        </button>
                    )}
                    <ReactToPrint
                        trigger={() => (
                            <button className="content-action-print">
                                <IoPrintOutline /> Print
                            </button>
                        )}
                        content={() => printComponentRef}
                    />
                </div>
            </div>
            {dataEdit.status !== "posted" && (
                <div className="content-add-new">
                    <button className="add-new-reimbursent" onClick={handleAddNew}>
                        <Fragment>
                            <IoAddOutline></IoAddOutline>New Entry
                        </Fragment>
                    </button>
                </div>
            )}
            <div className="content-table table-reimbursement-entry">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index} onClick={() => setDataEditEntry(row.original.data)}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {dataEditEntry && dataEdit.status !== "posted" && <ReimbursementPeriodEdit dataEditEntry={dataEditEntry} handleGoToPage={handleGoToPage} setDataEditEntry={setDataEditEntry} project={project} token={token} notify={notify} refreshToken={refreshToken} />}
            {deleteConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setDeleteConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleDeleteEntry();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Delete Entry?</p>
                </PopupConfirmation>
            )}
            {submitConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setSubmitConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Submit reimbursement?</p>
                </PopupConfirmation>
            )}
            {postedConfirmation && (
                <PopupConfirmation cancelAction={() => setPostedConfirmation(false)} footerAction={<Fragment></Fragment>}>
                    <p className="">Reimbursement already approved</p>
                </PopupConfirmation>
            )}
            <ReimbursementPrint printRef={(el) => (printComponentRef = el)} user={user} dataEdit={dataEdit} columns={columns} data={data} />
        </Fragment>
    );
};

const ReimbursementPeriodEdit = ({ dataEditEntry, handleGoToPage, setDataEditEntry, project, token, notify, refreshToken }) => {
    const dateNow = new Date();
    const dateEdit = dataEditEntry.date ? new Date(dataEditEntry.date) : new Date();

    const [inputDate, setInputDate] = useState(dateEdit);

    const handleChangeEdit = (key, value) => {
        console.log(value)
        setDataEditEntry({ ...dataEditEntry, [key]: value });
    };

    const handleSaveEdit = () => {
        if (dataEditEntry.id) {
            updateEntry({ handleGoToPage, notify, refreshToken, dataEditEntry: { ...dataEditEntry, date: dateFormat(inputDate, "yyyy-mm-dd") }, token, setDataEditEntry });
        } else {
            addEntry({ handleGoToPage, notify, refreshToken, dataEditEntry: { ...dataEditEntry, date: dateFormat(inputDate, "yyyy-mm-dd") }, token, setDataEditEntry });
        }
    };

    return (
        <div className="content-edit" key={dataEditEntry.id}>
            <div className="content-edit-title">
                <span>{dataEditEntry.id ? "Edit Reimbursement" : "New Reimbursement"}</span>
            </div>
            <div className="data-edit-content">
                <span className="reimbursement-date-edit">
                    <label>Date</label>
                    <DatePicker selected={inputDate} dateFormat="dd/MM/yyyy" onChange={(date) => setInputDate(date)} minDate={new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)} maxDate={new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)} />
                </span>
                <span className="reimbursement-project-edit">
                    <label>Project</label>
                    <select defaultValue={dataEditEntry.project_id} onChange={(e) => handleChangeEdit("project_id", e.target.value)}>
                        {project.map((pro) => (
                            <option key={pro.id} value={pro.id}>
                                {pro.code + " - " + pro.name}
                            </option>
                        ))}
                    </select>
                </span>
                <span className="reimbursement-transport-edit">
                    <label>Transport</label>
                    <NumberFormat thousandSeparator={"."} decimalSeparator={","} prefix={"Rp. "} defaultValue={dataEditEntry.transport} onValueChange={(values) => handleChangeEdit("transport", values.floatValue)} />
                </span>
                <span className="reimbursement-meal-edit">
                    <label>Meal</label>
                    <NumberFormat thousandSeparator={"."} decimalSeparator={","} prefix={"Rp. "} defaultValue={dataEditEntry.meal} onValueChange={(values) => handleChangeEdit("meal", values.floatValue)} />
                </span>
                <span className="reimbursement-fee-edit">
                    <label>Fee</label>
                    <NumberFormat thousandSeparator={"."} decimalSeparator={","} prefix={"Rp. "} defaultValue={dataEditEntry.fee} onValueChange={(values) => handleChangeEdit("fee", values.floatValue)} />
                </span>
                <span className="reimbursement-remark-edit">
                    <label>Remark</label>
                    <input type="text" defaultValue={dataEditEntry.remark} onChange={(e) => handleChangeEdit("remark", e.target.value)}></input>
                </span>
            </div>
            <div className="data-edit-actions">
                <button className="data-edit-close" onClick={() => setDataEditEntry(false)}>
                    <IoCloseOutline /> Close
                </button>
                <button className="data-edit-save" onClick={handleSaveEdit}>
                    <IoSaveOutline /> Save
                </button>
            </div>
        </div>
    );
};

export default Reimbursement;
