import useNavigation from "../App/useNavigation";
import Navigation from "./Navigation/Navigation";
import "./Dashboard.scss";

const Dashboard = () => {
    const {navigation} = useNavigation();
    
    return (
        <Navigation>
            <div className="dashboard-menu">
                {navigation.map((nav) => (
                    <a key={nav.id} className="dashboard-menu-item" href={process.env.REACT_APP_HOME_URL + '/' + nav.id}>
                        {nav.icon}
                        <span className="dashboard-menu-title">{nav.text}</span>
                    </a>
                ))}
            </div>
        </Navigation>
    );
};

export default Dashboard;
