import { useLocation } from "react-router-dom";
import useUser from "./useUser";
import { IoHomeOutline, IoTodayOutline, IoPersonOutline, IoFileTrayFullOutline, IoBriefcaseOutline, IoScaleOutline } from "react-icons/io5";
import { GrMoney } from "react-icons/gr";

const useNavigation = () => {
    const { user } = useUser();
    const location = useLocation();

    const navList = [
        { id: "timesheet", text: "Timesheet", roles: ["employee", "admin", "manager", "senior_architect", "junior_architect", "senior_landscape_designer", "junior_landscape_designer", "senior_cad_operator", "junior_cad_operator", "graphic_designer", "artwork_designer", "interior_designer", "landscape_architect"], icon: <IoTodayOutline /> },
        { id: "reimbursement", text: "Reimbursement", roles: ["employee", "admin", "manager", "senior_architect", "junior_architect", "senior_landscape_designer", "junior_landscape_designer", "senior_cad_operator", "junior_cad_operator", "graphic_designer", "artwork_designer", "interior_designer", "landscape_architect"], icon: <GrMoney /> },
        { id: "employee", text: "Employee", roles: ["supervisor"], icon: <IoPersonOutline /> },
        { id: "task", text: "Task", roles: ["supervisor"], icon: <IoFileTrayFullOutline /> },
        { id: "project", text: "Project", roles: ["supervisor"], icon: <IoBriefcaseOutline /> },
        { id: "phase", text: "Phase", roles: ["supervisor"], icon: <IoScaleOutline /> },
    ];

    const navUser = navList.filter((nav) => {
        return nav.roles.includes(user.role);
    });

    if (location.pathname !== "/" && location.pathname !== "/dashboard") {
        navUser.unshift({ id: "dashboard", text: "Dashboard", roles: ["supervisor", "employee"], icon: <IoHomeOutline /> });
    }

    return {
        navigation: navUser,
    };
};

export default useNavigation;
