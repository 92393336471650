import { useState, Fragment } from "react";
import useUser from "../App/useUser";
import useToken from "../App/useToken";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import { IoSaveOutline } from "react-icons/io5";
import { ReactSVG } from "react-svg";
import axios from "axios";

const submitChangeProfile = ({ token, setIsLoadingUser, notify, refreshToken, setUser, data }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/user/change-profile", data, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setUser(response.data.data);
                notify("success", "Success Change Profile");
            } else {
                notify("error", "Failed Change Profile");
            }

            setIsLoadingUser(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: submitChangeProfile, props: { token, setIsLoadingUser, notify, setUser, data } });
            } else {
                if (error.response.status < 500 && error.response !== undefined && typeof error.response.data === "object" && !Array.isArray(error.response.data) && error.response.data !== null) {
                    notify("error", error.response.data.message);
                } else {
                    notify("error", "Failed Change Profile");
                }

                console.log(error);
                setIsLoadingUser(false);
            }
        });
};

const submitChangePassword = ({ token, setIsLoadingPassword, notify, refreshToken, password }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/user/change-password", password, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                notify("success", "Success Change Password");
            } else {
                notify("error", "Failed Change Password");
            }

            setIsLoadingPassword(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: submitChangePassword, props: { token, setIsLoadingPassword, notify, password } });
            } else {
                notify("error", "Failed Change Password");
                setIsLoadingPassword(false);
                console.log(error);
            }
        });
};

const Profile = ({ notify }) => {
    const { user, setUser } = useUser();
    const { token, refreshToken } = useToken();
    const [data, setData] = useState({ name: user.name, email: user.email });
    const [password, setPassword] = useState({});
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [isLoadingPassword, setIsLoadingPassword] = useState(false);
    const [userConfirmation, setUserConfirmation] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);

    const handleChangeEdit = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const handleChangePassword = (key, value) => {
        setPassword({ ...password, [key]: value });
    };

    const saveProfile = () => {
        setUserConfirmation(true);
    };

    const savePassword = () => {
        setPasswordConfirmation(true);
    };

    const handleSaveUser = () => {
        setIsLoadingUser(true);
        submitChangeProfile({ token, setIsLoadingUser, notify, refreshToken, setUser, data });
    };

    const handleSavePassword = () => {
        if (password.password === password.retype) {
            setIsLoadingPassword(true);
            submitChangePassword({ token, setIsLoadingPassword, notify, refreshToken, password });
        } else {
            notify("error", "Password not match");
        }
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                <div className="content-title">
                    <span>User Profile</span>
                </div>
                <div className="content-profile">
                    <span className="profile-code-edit">
                        <label>Employee ID</label>
                        <input type="text" defaultValue={user.code} disabled={true}></input>
                    </span>
                    <span className="profile-name-edit">
                        <label>Name</label>
                        <input type="text" defaultValue={data.name} onChange={(e) => handleChangeEdit("name", e.target.value)}></input>
                    </span>
                    <span className="profile-email-edit">
                        <label>Email</label>
                        <input type="text" defaultValue={data.email} onChange={(e) => handleChangeEdit("email", e.target.value)}></input>
                    </span>
                    <button className="save-profile" onClick={() => saveProfile()}>
                        {isLoadingUser ? (
                            <ReactSVG src="loading.svg" />
                        ) : (
                            <Fragment>
                                <IoSaveOutline></IoSaveOutline> Save User
                            </Fragment>
                        )}
                    </button>
                </div>
                <div className="content-title change-password">
                    <span>Change Password</span>
                </div>
                <div className="content-profile-password">
                    <span className="profile-password-edit">
                        <label>New Password</label>
                        <input type="password" onChange={(e) => handleChangePassword("password", e.target.value)}></input>
                    </span>
                    <span className="profile-re-password-edit">
                        <label>Retype Password</label>
                        <input type="password" onChange={(e) => handleChangePassword("retype", e.target.value)}></input>
                    </span>
                    <button className="save-password" onClick={() => savePassword()}>
                        {isLoadingPassword ? (
                            <ReactSVG src="loading.svg" />
                        ) : (
                            <Fragment>
                                <IoSaveOutline></IoSaveOutline> Save Password
                            </Fragment>
                        )}
                    </button>
                </div>
                {userConfirmation && (
                    <PopupConfirmation
                        cancelAction={() => setUserConfirmation(false)}
                        footerAction={
                            <button
                                onClick={() => {
                                    handleSaveUser();
                                    setUserConfirmation(false);
                                }}
                            >
                                Continue
                            </button>
                        }
                    >
                        <p className="">Change Profile?</p>
                    </PopupConfirmation>
                )}
                {passwordConfirmation && (
                    <PopupConfirmation
                        cancelAction={() => setPasswordConfirmation(false)}
                        footerAction={
                            <button
                                onClick={() => {
                                    handleSavePassword();
                                    setPasswordConfirmation(false);
                                }}
                            >
                                Continue
                            </button>
                        }
                    >
                        <p className="">Change Password?</p>
                    </PopupConfirmation>
                )}
            </section>
        </NavigationFull>
    );
};

export default Profile;
