import { useEffect, useState, useMemo, Fragment } from "react";
import { useTable } from "react-table";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import { IoAddOutline, IoChevronDownOutline, IoSaveOutline, IoCloseOutline, IoCalendarClearOutline, IoTrashOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { MdOutlinePassword } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import useToken from "../App/useToken";
import useString from "../App/useString";
import axios from "axios";
import "./Dashboard.scss";

const getEmployee = ({ page, token, setEmployee, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, notify, setDeleteConfirmation }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/user/employee?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];
                response.data.data.forEach((prj) => {
                    tableData.push({
                        col1: prj.code,
                        col2: prj.name,
                        col3: prj.email,
                        col4: prj.sallary,
                        col5: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        col6: <IoCalendarClearOutline onClick={() => (window.location.href = process.env.REACT_APP_HOME_URL + "/employee/" + prj.id + "/timesheet")}></IoCalendarClearOutline>,
                        col7: <GrMoney onClick={() => (window.location.href = process.env.REACT_APP_HOME_URL + "/employee/" + prj.id + "/reimbursement")}></GrMoney>,
                        col8: <IoTrashOutline onClick={() => setDeleteConfirmation(prj.id)}></IoTrashOutline>
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setEmployee([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getEmployee, props: { page, token, setEmployee, setCurrentPage, setLastPage, setLoading, setDataEdit, notify, setDeleteConfirmation } });
            } else {
                console.log(error);
                setLoading(false);
                notify("error", error.response && error.response.data ? error.response.data.message : "Data Not Saved");
            }
        });
};

const updateEmployee = ({ dataEdit, handleGoToPage, notify, token, refreshToken, currentPage, getAllStrings }) => {
    axios
        .put(process.env.REACT_APP_API_URL + "/user/employee/" + dataEdit.id, dataEdit, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(currentPage);
                notify("success", "Success Save Data");
            }
        })
        .catch((error) => {
            let message = "Data Not Saved";

            if (refreshToken) {
                refreshToken({ callback: updateEmployee, props: { handleGoToPage, notify, currentPage, dataEdit, token, getAllStrings } });
            } else {
                console.log(error);
                console.log(error.response);

                if (error.response && error.response.status && error.response.status === 422) {
                    message = getAllStrings(error.response.data);
                }

                notify("error", JSON.stringify(message));
            }
        });
};

const addEmployee = ({ dataEdit, handleGoToPage, notify, token, refreshToken, setDataEdit, getAllStrings }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/user/employee", dataEdit, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(1);
                setDataEdit({ ...dataEdit, id: response.data.data.id });
                notify("success", "Success Save Data");
            }
        })
        .catch((error) => {
            let message = "Data Not Saved";

            if (refreshToken) {
                refreshToken({ callback: addEmployee, props: { dataEdit, handleGoToPage, notify, token, setDataEdit, getAllStrings } });
            } else {
                console.log(error.response);

                if (error.response && error.response.status && error.response.status === 422) {
                    message = getAllStrings(error.response.data);
                }

                notify("error", JSON.stringify(message));
            }
        });
};

const deleteEmployee = ({ id, token, refreshToken, handleGoToPage, currentPage, notify, setDeleteConfirmation }) => {
    axios
        .delete(process.env.REACT_APP_API_URL + "/user/employee/" + id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && response.data) {
                handleGoToPage(currentPage);
            }

            setDeleteConfirmation(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: deleteEmployee, props: { id, token, handleGoToPage, currentPage, notify, setDeleteConfirmation } });
            } else {
                console.log(error);
                notify("error", "Data Not Deleted");
                setDeleteConfirmation(false);
            }
        });
};

const resetPassword = ({ handleGoToPage, notify, currentPage, refreshToken, dataEdit, token, setResetPasswordConfirmation }) => {
    axios
        .put(process.env.REACT_APP_API_URL + "/user/employee/" + dataEdit.id + "/password", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && response.data) {
                notify("success", "Success reset password");
                handleGoToPage(currentPage);
            }

            setResetPasswordConfirmation(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: resetPassword, props: { handleGoToPage, notify, currentPage, dataEdit, token, setResetPasswordConfirmation } });
            } else {
                console.log(error);
                notify("error", "Password not reset");
                setResetPasswordConfirmation(false);
            }
        });
};

const Employee = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [employee, setEmployee] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const { token, refreshToken } = useToken();
    const { getAllStrings } = useString();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const data = useMemo(() => employee, [employee]);
    const columns = useMemo(
        () => [
            {
                Header: "Code",
                accessor: "col1",
            },
            {
                Header: "Employee Name",
                accessor: "col2",
            },
            {
                Header: "Email",
                accessor: "col3",
            },
            {
                Header: "Sallary",
                accessor: "col4",
            },
            {
                Header: "Edit",
                accessor: "col5",
            },
            {
                Header: "Timesheet",
                accessor: "col6",
            },
            {
                Header: "Reimbursement",
                accessor: "col7",
            },
            {
                Header: "Remove",
                accessor: "col8",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getEmployee({ page: 1, token, setEmployee, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, notify, setDeleteConfirmation });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getEmployee({ page, token, setEmployee, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, setDeleteConfirmation });
    };

    const handleDeleteEmployee = () => {
        setDataEdit(false);
        deleteEmployee({ id: deleteConfirmation, token, refreshToken, handleGoToPage, currentPage, notify, setDeleteConfirmation });
    };

    const randomString = () => {
        let result = "";
        const length = 32;
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];

        return result;
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                <div className="content-title">
                    <span>Employees</span>
                </div>
                <div className="content-add-new">
                    <button className="add-new-employee" onClick={() => setDataEdit({ role: "admin", password: randomString() })}>
                        <IoAddOutline></IoAddOutline>New Employee
                    </button>
                </div>
                <div className="content-table table-employee">
                    {!loading && (
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="content-pagination">
                    <span className="page-information">
                        Page {currentPage} of {lastPage}
                    </span>
                    <div className="page-actions">
                        {currentPage > 1 && (
                            <Fragment>
                                <div className="select-first" onClick={() => handleGoToPage(1)}>
                                    <BiChevronsLeft />
                                </div>
                                <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                    <BiChevronLeft />
                                </div>
                            </Fragment>
                        )}
                        {lastPage > 1 && (
                            <div className="select-page">
                                <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                            </div>
                        )}
                        {currentPage < lastPage && (
                            <Fragment>
                                <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                    <BiChevronRight />
                                </div>
                                <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                    <BiChevronsRight />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
                {dataEdit && <EmployeeEdit dataEdit={dataEdit} setDataEdit={setDataEdit} token={token} handleGoToPage={handleGoToPage} currentPage={currentPage} notify={notify} refreshToken={refreshToken} getAllStrings={getAllStrings} />}
                {deleteConfirmation && (
                    <PopupConfirmation
                        cancelAction={() => setDeleteConfirmation(false)}
                        footerAction={
                            <button
                                onClick={() => {
                                    handleDeleteEmployee();
                                }}
                            >
                                Continue
                            </button>
                        }
                    >
                        <p className="">Delete Employee?</p>
                    </PopupConfirmation>
                )}
            </section>
        </NavigationFull>
    );
};

const EmployeeEdit = ({ dataEdit, setDataEdit, token, handleGoToPage, currentPage, notify, refreshToken, getAllStrings }) => {
    const [resetPasswordConfirmation, setResetPasswordConfirmation] = useState(false);

    const handleChangeEdit = (key, value) => {
        setDataEdit({ ...dataEdit, [key]: value });
    };

    const handleSaveEdit = () => {
        if (dataEdit.id) {
            updateEmployee({ handleGoToPage, notify, currentPage, refreshToken, dataEdit, token, getAllStrings });
        } else {
            addEmployee({ handleGoToPage, notify, refreshToken, dataEdit, token, setDataEdit, getAllStrings });
        }
    };

    const handleResetPassword = () => {
        resetPassword({ handleGoToPage, notify, currentPage, refreshToken, dataEdit, token, setResetPasswordConfirmation });
    };

    return (
        <div className="content-edit" key={dataEdit.id}>
            <div className="content-edit-title">
                <span>{dataEdit.id ? "Edit Employee " + dataEdit.code : "New Employee " + (dataEdit.code ? dataEdit.code : "")}</span>
            </div>
            <div className="data-edit-content">
                <span className="employee-code-edit">
                    <label>Employee Number</label>
                    <input type="text" defaultValue={dataEdit.code} onChange={(e) => handleChangeEdit("code", e.target.value)}></input>
                </span>
                <span className="employee-code-name">
                    <label>Employee Name</label>
                    <input type="text" defaultValue={dataEdit.name} onChange={(e) => handleChangeEdit("name", e.target.value)}></input>
                </span>
                <span className="employee-code-email">
                    <label>Employee Email</label>
                    <input type="text" defaultValue={dataEdit.email} onChange={(e) => handleChangeEdit("email", e.target.value)}></input>
                </span>
                {!dataEdit.id && (
                    <span className="employee-code-password">
                        <label>Account Password</label>
                        <input type="text" defaultValue={dataEdit.password} onChange={(e) => handleChangeEdit("password", e.target.value)}></input>
                    </span>
                )}
                <span className="employee-code-sallary">
                    <label>Employee Sallary / Hour</label>
                    <input type="number" defaultValue={dataEdit.sallary} onChange={(e) => handleChangeEdit("sallary", e.target.value)}></input>
                </span>
                <span className="employee-code-role">
                    <label>Employee Role</label>
                    <select defaultValue={dataEdit.role} onChange={(e) => handleChangeEdit("role", e.target.value)}>
                        <option value="admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="senior_architect">Senior Architect</option>
                        <option value="junior_architect">Junior Architect</option>
                        <option value="senior_landscape_designer">Senior Landscape Designer</option>
                        <option value="junior_landscape_designer">Junior Landscape Designer</option>
                        <option value="senior_cad_operator">Senior Cad Operator</option>
                        <option value="junior_cad_operator">Junior Cad Operator</option>
                        <option value="graphic_designer">Graphic Designer</option>
                        <option value="artwork_designer">Artwork Designer</option>
                        <option value="interior_designer">Interior Designer</option>
                        <option value="landscape_architect">Landscape Architect</option>
                        <option value="supervisor">Supervisor</option>
                    </select>
                </span>
            </div>
            <div className="data-edit-actions">
                <button className="data-edit-close" onClick={() => setDataEdit(false)}>
                    <IoCloseOutline /> Close
                </button>
                <button className="data-edit-save" onClick={handleSaveEdit}>
                    <IoSaveOutline /> Save
                </button>
                {dataEdit.id && (
                    <button className="data-edit-reset-password" onClick={() => setResetPasswordConfirmation(true)}>
                        <MdOutlinePassword /> Reset Password
                    </button>
                )}
            </div>
            {resetPasswordConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setResetPasswordConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleResetPassword();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">
                        Password will be returned to <strong>UCDSbali1234@</strong> for {dataEdit.name}. Change the password immediately after reset.
                    </p>
                </PopupConfirmation>
            )}
        </div>
    );
};

export default Employee;
