import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import { useTable } from "react-table";
import useToken from "../App/useToken";
import useUser from "../App/useUser";
import useDate from "../App/useDate";
import { IoAddOutline, IoChevronDownOutline, IoSaveOutline, IoCloseOutline, IoPrintOutline, IoCloudUploadOutline, IoTrashOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight, BiPencil } from "react-icons/bi";
import { ReactSVG } from "react-svg";
import ReactToPrint from "react-to-print";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import TimesheetPrint from "./TimesheetPrint";
import axios from "axios";
import dateFormat from "dateformat";
import "./Dashboard.scss";

const getTimesheet = ({ page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete }) => {
    const today = new Date();
    const statusMap = {
        draft: "Draft",
        posted: "Posted",
        submitted: "Submitted",
    };

    const countHours = (project) => {
        let hours = 0;

        if (Array.isArray(project.entries)) {
            project.entries.forEach((entry) => {
                if (Array.isArray(entry.dates)) {
                    entry.dates.forEach((date) => {
                        hours += typeof date.hour === "number" && !isNaN(date.hour) ? date.hour : 0;
                    });
                }
            });
        }

        return hours;
    };

    axios
        .get(process.env.REACT_APP_API_URL + "/timesheet?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];
                response.data.data.forEach((prj) => {
                    const endDate = new Date(prj.end_date);

                    tableData.push({
                        col1: prj.number,
                        col2: <span className={"status-" + prj.status}>{statusMap[prj.status]}</span>,
                        col3: prj.start_date,
                        col4: prj.end_date,
                        col5: Math.round(countHours(prj) * 100) / 100,
                        col6: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        col7: prj.status === "draft" && endDate > today ? <IoTrashOutline onClick={(e) => handleDelete(e, prj)}></IoTrashOutline> : <div></div>,
                        data: prj,
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setTimesheet([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getTimesheet, props: { page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, handleDelete } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const getProject = ({ token, setProject, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/project/ongoing", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setProject([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getProject, props: { token, setProject } });
            } else {
                console.log(error);
            }
        });
};

const getTask = ({ token, setTask, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/task/all", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setTask([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getTask, props: { token, setTask } });
            } else {
                console.log(error);
            }
        });
};

const getMilestone = ({ token, setMilestone, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/milestone/all", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setMilestone([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getMilestone, props: { token, setMilestone } });
            } else {
                console.log(error);
            }
        });
};

const getEntriesByWeek = ({ week, setEntries, project, milestone, task, setLoading, handleDeleteEntries, setEntryNote, allEntries, dataStatus, changeHour, changeNote }) => {
    const entries = [];
    const weekEntries = allEntries.filter((r) => r.week === week && r.status !== "deleted");

    weekEntries.forEach((data) => {
        const projectOption = project.find((r) => r.id === data.project_id);
        const milestoneOption = milestone.find((r) => r.id === data.milestone_id);
        const taskOption = task.find((r) => r.id === data.task_id);

        const entryObj = {
            col1: <span>{data.status === "saved" ? dataStatus : data.status}</span>,
            col2: projectOption ? projectOption.code + " - " + projectOption.name : "",
            col3: milestoneOption ? milestoneOption.code + " - " + milestoneOption.name : "",
            col4: taskOption ? taskOption.code + " - " + taskOption.name : "",
            col12: dataStatus !== "posted" ? <IoTrashOutline onClick={() => handleDeleteEntries(data.id)} /> : <div></div>,
        };

        if (Array.isArray(data.dates)) {
            data.dates.forEach((date) => {
                if (date.day === "monday") {
                    entryObj.col5 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "monday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "monday" })} />
                        </span>
                    );
                }

                if (date.day === "tuesday") {
                    entryObj.col6 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "tuesday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "tuesday" })} />
                        </span>
                    );
                }

                if (date.day === "wednesday") {
                    entryObj.col7 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "wednesday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "wednesday" })} />
                        </span>
                    );
                }

                if (date.day === "thursday") {
                    entryObj.col8 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "thursday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "thursday" })} />
                        </span>
                    );
                }

                if (date.day === "friday") {
                    entryObj.col9 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "friday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "friday" })} />
                        </span>
                    );
                }

                if (date.day === "saturday") {
                    entryObj.col10 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "saturday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "saturday" })} />
                        </span>
                    );
                }

                if (date.day === "sunday") {
                    entryObj.col11 = (
                        <span>
                            {dataStatus === "posted" ? <Fragment>{date.hour}</Fragment> : <input type="number" step="0.01" min="0.00" max="1000000.00" defaultValue={date.hour} onChange={(e) => changeHour(data.id, "sunday", parseFloat(e.target.value))}></input>}
                            <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote({ text: date.note, id: data.id, day: "sunday" })} />
                        </span>
                    );
                }
            });
        }

        entries.push(entryObj);
    });

    setEntries([...entries]);
    setLoading(false);
};

const getEntries = ({ allEntries, dataEdit, getDates, formatDate }) => {
    const countHours = (entries, day) => {
        let hours = 0;

        entries.forEach((entry) => {
            if (Array.isArray(entry.dates)) {
                entry.dates.forEach((date) => {
                    if (date.day === day) {
                        hours += date.hour;
                    }
                });
            }
        });

        return hours;
    };

    const listDates = getDates(dataEdit.start_date, dataEdit.end_date);
    const periodList = [];

    let columns = {};
    let week = 1;
    let weekHour = 0;
    let dayHour = 0;
    let periodHour = 0;
    let startWeek = formatDate(listDates[0]);
    let endWeek = formatDate(listDates[0]);

    listDates.forEach((date) => {
        const d = new Date(date);
        let weekEntries = allEntries.filter((r) => r.week === week && r.status !== "deleted");

        switch (d.getDay()) {
            case 0:
                // Sunday
                dayHour = countHours(weekEntries, "sunday");
                weekHour += dayHour;
                columns["col10"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 1:
                // Monday
                if (Object.entries(columns).length > 0) {
                    columns["col1"] = week;
                    columns["col2"] = formatDate(startWeek);
                    columns["col3"] = formatDate(endWeek);
                    columns["col11"] = weekHour;

                    periodHour += weekHour;
                    week++;
                    weekHour = 0;
                    startWeek = formatDate(date);
                    endWeek = formatDate(date);

                    periodList.push(columns);
                    weekEntries = allEntries.filter((r) => r.week === week && r.status !== "deleted");
                }

                columns = {};
                dayHour = countHours(weekEntries, "monday");
                weekHour += dayHour;
                columns["col4"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 2:
                // Tuesday
                dayHour = countHours(weekEntries, "tuesday");
                weekHour += dayHour;
                columns["col5"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 3:
                // Wednesday
                dayHour = countHours(weekEntries, "wednesday");
                weekHour += dayHour;
                columns["col6"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 4:
                // Thursday
                dayHour = countHours(weekEntries, "thursday");
                weekHour += dayHour;
                columns["col7"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 5:
                // Friday
                dayHour = countHours(weekEntries, "friday");
                weekHour += dayHour;
                columns["col8"] = [countHours(weekEntries, "friday"), formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 6:
            default:
                // Saturday
                dayHour = countHours(weekEntries, "saturday");
                weekHour += dayHour;
                columns["col9"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
        }

        endWeek = date;
    });

    if (Object.entries(columns).length > 0) {
        columns["col1"] = week;
        columns["col2"] = formatDate(startWeek);
        columns["col3"] = formatDate(endWeek);
        columns["col11"] = Math.round(weekHour * 100) / 100;

        periodHour += weekHour;
        periodList.push(columns);
    }

    periodList.push({
        col10: "Total",
        col11: Math.round(periodHour * 100) / 100,
    });

    return periodList;
};

const getAllEntries = ({ token, setAllEntries, dataEdit, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/timesheet-entry/timesheet/" + dataEdit.id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && (response.data !== null) & Array.isArray(response.data.data)) {
                const allEntries = response.data.data;

                allEntries.forEach((entry) => {
                    entry.status = "saved";
                });

                setAllEntries([...allEntries]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getAllEntries, props: { token, setAllEntries, refreshToken } });
            } else {
                console.log(error);
            }
        });
};

const submitTimesheet = ({ dataEdit, setDataEdit, token, setIsLoadingSubmit, notify, refreshToken }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/timesheet/" + dataEdit.id + "/submit", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setDataEdit({ ...dataEdit, status: "submitted" });
                notify("success", "Success Submit Timesheet");
            } else {
                notify("error", "Failed Submit Timesheet");
            }

            setIsLoadingSubmit(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: submitTimesheet, props: { dataEdit, setDataEdit, token, setIsLoadingSubmit, notify } });
            } else {
                notify("error", "Failed Submit Timesheet");
                setIsLoadingSubmit(false);
                console.log(error);
            }
        });
};

const saveTimesheet = ({ dataEdit, token, setIsLoadingSave, notify, refreshToken, allEntries, setAllEntries, setEntriesChanged, getAllEntries, setRowActive, setEntryEdit }) => {
    const saveEntries = allEntries.filter((r) => r.status === "new" || r.status === "deleted" || r.status === "update");
    const payload = {
        data: saveEntries,
    };

    if (saveEntries.length > 0) {
        axios
            .post(process.env.REACT_APP_API_URL + "/timesheet/" + dataEdit.id + "/save", payload, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token.token}`,
                },
            })
            .then((response) => {
                if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                    getAllEntries({ token, setAllEntries, dataEdit, refreshToken });
                    setRowActive(-1);
                    setEntryEdit(false);
                    setEntriesChanged(false);
                    notify("success", "Success Save Timesheet");
                } else {
                    notify("error", "Failed Save Timesheet");
                }

                setIsLoadingSave(false);
            })
            .catch((error) => {
                if (refreshToken) {
                    refreshToken({ callback: saveTimesheet, props: { dataEdit, token, setIsLoadingSave, notify, allEntries, setAllEntries, setEntriesChanged, getAllEntries, setRowActive, setEntryEdit } });
                } else {
                    notify("error", "Failed Save Timesheet");
                    setIsLoadingSave(false);
                    console.log(error);
                }
            });
    } else {
        getAllEntries({ token, setAllEntries, dataEdit, refreshToken });
        setRowActive(-1);
        setEntryEdit(false);
        setEntriesChanged(false);
        notify("success", "Success Save Timesheet");
        setIsLoadingSave(false);
    }
};

const addTimesheet = ({ token, handleGoToPage, setNewLoading, refreshToken }) => {
    setNewLoading(true);

    axios
        .post(process.env.REACT_APP_API_URL + "/timesheet", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(1);
            }

            setNewLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: addTimesheet, props: { token, handleGoToPage, setNewLoading } });
            } else {
                setNewLoading(false);
                console.log(error);
            }
        });
};

const deleteTimesheet = ({ currentPage, handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation }) => {
    axios
        .delete(process.env.REACT_APP_API_URL + "/timesheet/" + dataDelete.id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && response.data) {
                handleGoToPage(currentPage);
            }

            setDeleteConfirmation(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: deleteTimesheet, props: { currentPage, handleGoToPage, token, dataDelete, setDeleteConfirmation } });
            }

            setDeleteConfirmation();
        });
};

const Timesheet = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [newLoading, setNewLoading] = useState(false);
    const [timesheet, setTimesheet] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const [project, setProject] = useState([]);
    const [task, setTask] = useState([]);
    const [milestone, setMilestone] = useState([]);
    const { token, refreshToken } = useToken();
    const { user } = useUser();
    const { getDates, formatDate } = useDate();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const data = useMemo(() => timesheet, [timesheet]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Status",
                accessor: "col2",
            },
            {
                Header: "Start Date",
                accessor: "col3",
            },
            {
                Header: "End Date",
                accessor: "col4",
            },
            {
                Header: "Hours",
                accessor: "col5",
            },
            {
                Header: "",
                accessor: "col6",
            },
            {
                Header: "",
                accessor: "col7",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getTimesheet({ page: 1, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete });
        getProject({ token, setProject, refreshToken });
        getTask({ token, setTask, refreshToken });
        getMilestone({ token, setMilestone, refreshToken });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getTimesheet({ page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDelete });
    };

    const handleAddNew = () => {
        addTimesheet({ token, handleGoToPage, setNewLoading, refreshToken });
    };

    const handleDelete = (e, prj) => {
        e.preventDefault();
        e.stopPropagation();

        setDeleteConfirmation(prj);
    };

    const handleDeleteTimesheet = () => {
        const dataDelete = deleteConfirmation;
        deleteTimesheet({ currentPage, handleGoToPage, token, refreshToken, dataDelete, setDeleteConfirmation });
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                {dataEdit ? (
                    <TimesheetPeriod user={user} dataEdit={dataEdit} setDataEdit={setDataEdit} project={project} task={task} milestone={milestone} token={token} refreshToken={refreshToken} notify={notify} getDates={getDates} formatDate={formatDate} />
                ) : (
                    <Fragment>
                        <div className="content-title">
                            <span>Timesheets</span>
                        </div>
                        <div className="content-add-new">
                            <button className="add-new-timesheet" onClick={handleAddNew}>
                                {newLoading ? (
                                    <ReactSVG src="loading.svg" />
                                ) : (
                                    <Fragment>
                                        <IoAddOutline></IoAddOutline>New Timesheet
                                    </Fragment>
                                )}
                            </button>
                        </div>
                        <div className="content-table table-timesheet">
                            {!loading && (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps()} key={index}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={index} onClick={() => setDataEdit(row.original.data)}>
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <td {...cell.getCellProps()} key={index}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="content-pagination">
                            <span className="page-information">
                                Page {currentPage} of {lastPage}
                            </span>
                            <div className="page-actions">
                                {currentPage > 1 && (
                                    <Fragment>
                                        <div className="select-first" onClick={() => handleGoToPage(1)}>
                                            <BiChevronsLeft />
                                        </div>
                                        <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                            <BiChevronLeft />
                                        </div>
                                    </Fragment>
                                )}
                                {lastPage > 1 && (
                                    <div className="select-page">
                                        <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                                    </div>
                                )}
                                {currentPage < lastPage && (
                                    <Fragment>
                                        <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                            <BiChevronRight />
                                        </div>
                                        <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                            <BiChevronsRight />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                        {deleteConfirmation && (
                            <PopupConfirmation
                                cancelAction={() => setDeleteConfirmation(false)}
                                footerAction={
                                    <button
                                        onClick={() => {
                                            handleDeleteTimesheet();
                                        }}
                                    >
                                        Continue
                                    </button>
                                }
                            >
                                <p className="">Delete Timesheet?</p>
                            </PopupConfirmation>
                        )}
                    </Fragment>
                )}
            </section>
        </NavigationFull>
    );
};

const TimesheetPeriod = ({ user, dataEdit, setDataEdit, project, task, milestone, token, refreshToken, notify, getDates, formatDate }) => {
    const [loading, setLoading] = useState(true);
    const [rowActive, setRowActive] = useState(-1);
    const [entryEdit, setEntryEdit] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [allEntries, setAllEntries] = useState([]);
    const [entriesChanged, setEntriesChanged] = useState(false);
    const [closeConfirmation, setCloseConfirmation] = useState(false);
    const [submitConfirmation, setSubmitConfirmation] = useState(false);
    const [notSaveConfirmation, setNotSaveConfirmation] = useState(false);
    const [saveConfirmation, setSaveConfirmation] = useState(false);
    const [postedConfirmation, setPostedConfirmation] = useState(false);
    const [newID, setNewID] = useState(-1);

    let printComponentRef = useRef();
    const period = getEntries({ dataEdit, allEntries, getDates, formatDate });

    const data = useMemo(() => period, [period]);
    const columns = useMemo(
        () => [
            {
                Header: "Week",
                accessor: "col1",
            },
            {
                Header: "Start",
                accessor: "col2",
            },
            {
                Header: "End",
                accessor: "col3",
            },
            {
                Header: "Mon",
                accessor: "col4",
            },
            {
                Header: "Tue",
                accessor: "col5",
            },
            {
                Header: "Wed",
                accessor: "col6",
            },
            {
                Header: "Thu",
                accessor: "col7",
            },
            {
                Header: "Fri",
                accessor: "col8",
            },
            {
                Header: "Sat",
                accessor: "col9",
            },
            {
                Header: "Sun",
                accessor: "col10",
            },
            {
                Header: "Hours",
                accessor: "col11",
            },
        ],
        []
    );

    useEffect(() => {
        setLoading(false);
        getAllEntries({ token, setAllEntries, dataEdit, refreshToken });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    const handleRowClick = (row) => {
        if (row.index < period.length - 1) {
            setRowActive(row.index);
            setEntryEdit(period[row.index]);
        }
    };

    const handleSubmitButton = () => {
        if (dataEdit.status !== "submitted") {
            if (dataEdit.status === "posted") {
                setPostedConfirmation(true);
            } else {
                setSubmitConfirmation(true);
            }
        }
    };

    const handleCloseButton = () => {
        if (entriesChanged) {
            setCloseConfirmation(true);
        } else {
            handleCloseRedirect();
        }
    };

    const handleSaveButton = () => {
        if (entriesChanged) {
            setSaveConfirmation(true);
        } else {
            setNotSaveConfirmation(true);
        }
    };

    const handleSubmit = () => {
        if (dataEdit.status !== "submitted") {
            setIsLoadingSubmit(true);
            submitTimesheet({ dataEdit, setDataEdit, token, setIsLoadingSubmit, notify, refreshToken });
            setSubmitConfirmation(false);
        }
    };

    const handleSave = () => {
        setIsLoadingSave(true);
        saveTimesheet({ dataEdit, token, setIsLoadingSave, notify, refreshToken, allEntries, setAllEntries, setEntriesChanged, getAllEntries, setRowActive, setEntryEdit });
        setSaveConfirmation(false);
    };

    const handleCloseRedirect = () => {
        window.location.href = process.env.REACT_APP_HOME_URL + "/timesheet";
    };

    const getSubmitNotice = () => {
        let week = 1;
        let allSaved = true;

        const zeroHour = [];
        const moreHour = [];
        const dayMap = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const listDates = getDates(dataEdit.start_date, dataEdit.end_date);
        const listDatesMap = listDates.map((d, index) => {
            const day = new Date(d).getDay();

            if (day === 1 && index !== 0) {
                week++;
            }

            return {
                date: d,
                hour: 0,
                day: dayMap[day],
                week,
            };
        });

        allEntries.forEach((e) => {
            console.log(e)
            if (e.status === "saved") {
                e.dates.forEach((d) => {
                    const findIndex = listDatesMap.findIndex((x) => x.day === d.day && e.week === x.week);

                    if (findIndex >= 0) {
                        listDatesMap[findIndex].hour += d.hour;
                    }
                });
            } else {
                allSaved = false;
            }
        });

        listDatesMap.forEach((d) => {
            if (d.day !== "saturday" && d.day !== "sunday") {
                if (d.hour <= 0) {
                    zeroHour.push(dateFormat(d.date, "dd/mm/yyyy"));
                } else if (d.hour > 8) {
                    moreHour.push(dateFormat(d.date, "dd/mm/yyyy"));
                }
            }
        });

        return (
            <Fragment>
                {!allSaved && <p>There are some data that are not saved yet</p>}
                {zeroHour.length > 0 && (
                    <Fragment>
                        <p style={!allSaved ? { marginTop: "20px" } : {}}>The following dates is still empty:</p>
                        <ul style={{ marginLeft: "30px" }}>
                            {zeroHour.map((day) => (
                                <li key={day}>{day}</li>
                            ))}
                        </ul>
                    </Fragment>
                )}
                {moreHour.length > 0 && (
                    <Fragment>
                        <p style={zeroHour.length > 0 ? { marginTop: "20px" } : {}}>The following dates has more than 8 hours:</p>
                        <ul style={{ marginLeft: "30px" }}>
                            {moreHour.map((day) => (
                                <li key={day}>{day}</li>
                            ))}
                        </ul>
                    </Fragment>
                )}
                <p style={{ marginTop: "20px" }}>Submit Timesheet?</p>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="content-title">
                <span>Period Ending {formatDate(dataEdit.end_date)}</span>
                <span className="content-actions">
                    <button className="content-action-back" onClick={() => handleCloseButton()}>
                        <IoCloseOutline /> Close
                    </button>
                    {dataEdit.status !== "posted" && (
                        <Fragment>
                            <button
                                className="content-action-save"
                                onClick={() => {
                                    handleSaveButton();
                                }}
                            >
                                {isLoadingSave ? (
                                    <ReactSVG className="loading-svg" src="loading.svg" />
                                ) : (
                                    <Fragment>
                                        <IoSaveOutline /> Save
                                    </Fragment>
                                )}
                            </button>
                            <button className={dataEdit.status === "submitted" ? "content-action-submit submitted" : "content-action-submit"} onClick={handleSubmitButton}>
                                {isLoadingSubmit ? (
                                    <ReactSVG className="loading-svg" src="loading.svg" />
                                ) : (
                                    <Fragment>
                                        <IoCloudUploadOutline /> {dataEdit.status === "submitted" ? "Submitted" : "Submit"}
                                    </Fragment>
                                )}
                            </button>
                        </Fragment>
                    )}
                    <ReactToPrint
                        trigger={() => (
                            <button className="content-action-print">
                                <IoPrintOutline /> Print
                            </button>
                        )}
                        content={() => printComponentRef}
                    />
                </span>
            </div>
            <div className="content-table table-timesheet-period">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={() => handleRowClick(row)} className={index === rowActive ? "active" : ""} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {closeConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setCloseConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleCloseRedirect();
                                setCloseConfirmation(false);
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Unsaved data will be lost</p>
                </PopupConfirmation>
            )}
            {submitConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setSubmitConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    {getSubmitNotice()}
                </PopupConfirmation>
            )}
            {notSaveConfirmation && (
                <PopupConfirmation cancelAction={() => setNotSaveConfirmation(false)} footerAction={<Fragment></Fragment>}>
                    <p className="">No data changes</p>
                </PopupConfirmation>
            )}
            {saveConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setSaveConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Save timesheet?</p>
                </PopupConfirmation>
            )}
            {postedConfirmation && (
                <PopupConfirmation cancelAction={() => setPostedConfirmation(false)} footerAction={<Fragment></Fragment>}>
                    <p className="">Timesheet already approved</p>
                </PopupConfirmation>
            )}
            {entryEdit && <TimesheetEntries dataEdit={dataEdit} entryEdit={entryEdit} key={entryEdit.col1} timesheetID={dataEdit.id} project={project} task={task} milestone={milestone} week={rowActive + 1} allEntries={allEntries} setAllEntries={setAllEntries} setEntriesChanged={setEntriesChanged} newID={newID} setNewID={setNewID} />}
            <TimesheetPrint printRef={(el) => (printComponentRef = el)} user={user} dataEdit={dataEdit} allEntries={allEntries} project={project} task={task} milestone={milestone} />
        </Fragment>
    );
};

const TimesheetEntries = ({ entryEdit, dataEdit, timesheetID, project, task, milestone, week, allEntries, setAllEntries, setEntriesChanged, newID, setNewID }) => {
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entryNew, setEntryNew] = useState(false);
    const [header, setHeader] = useState([]);
    const [entryNote, setEntryNote] = useState(false);

    const data = useMemo(() => entries, [entries]);
    const columns = useMemo(() => header, [header]);

    const optionProjectDefault = project.length > 0 ? project[0].id : "";
    const optionTaskDefault = task.length > 0 ? task[0].id : "";
    const optionMilestoneDefault = milestone.length > 0 ? milestone[0].id : "";

    const changeHour = (id, day, hour) => {
        const findIndex = allEntries.findIndex((x) => x.id === id);

        if (findIndex >= 0) {
            if (Array.isArray(allEntries[findIndex].dates)) {
                const dateIndex = allEntries[findIndex].dates.findIndex((x) => x.day === day);

                if (dateIndex >= 0) {
                    allEntries[findIndex].dates[dateIndex].hour = !isNaN(hour) ? hour : 0;

                    if (allEntries[findIndex].status === "saved") {
                        allEntries[findIndex].status = "update";
                    }
                }
            }
        }

        setAllEntries([...allEntries]);
        setEntriesChanged(true);
    };

    const changeNote = (id, day, note) => {
        const findIndex = allEntries.findIndex((x) => x.id === id);

        if (findIndex >= 0) {
            if (Array.isArray(allEntries[findIndex].dates)) {
                const dateIndex = allEntries[findIndex].dates.findIndex((x) => x.day === day);

                if (dateIndex >= 0) {
                    allEntries[findIndex].dates[dateIndex].note = note;

                    if (allEntries[findIndex].status === "saved") {
                        allEntries[findIndex].status = "update";
                    }
                }
            }
        }

        setAllEntries([...allEntries]);
        setEntriesChanged(true);
    };

    const handleEntriesChange = () => {
        getEntriesByWeek({ week, setEntries, project, milestone, task, setLoading, setEntryNote, allEntries, handleDeleteEntries, dataStatus: dataEdit.status, changeHour, changeNote });
    };

    const handleAddNew = () => {
        setEntryNew({
            id: newID,
            timesheet_id: timesheetID,
            project_id: optionProjectDefault,
            milestone_id: optionMilestoneDefault,
            task_id: optionTaskDefault,
            week: week,
            dates: [],
            status: "new",
        });

        setNewID(newID - 1);
        setEntriesChanged(true);
    };

    const handleDeleteEntries = (entryID) => {
        let deletedIndex;

        allEntries.forEach((entry, index) => {
            if (entry.id === entryID) {
                if (entryID > 0) {
                    allEntries[index].status = "deleted";
                } else {
                    deletedIndex = index;
                }
            }
        });

        if (deletedIndex !== undefined) {
            allEntries.splice(deletedIndex, 1);
        }

        setAllEntries([...allEntries]);
        setEntriesChanged(true);
        handleEntriesChange();
    };

    useEffect(() => {
        const columnsHeader = [
            {
                Header: "Status",
                accessor: "col1",
            },
            {
                Header: "Project",
                accessor: "col2",
            },
            {
                Header: "Milestone / Phase",
                accessor: "col3",
            },
            {
                Header: "Task",
                accessor: "col4",
            },
        ];

        if ("col4" in entryEdit) {
            columnsHeader.push({ Header: "Mon, " + entryEdit.col4[1].props.children, accessor: "col5" });
        }

        if ("col5" in entryEdit) {
            columnsHeader.push({ Header: "Tue, " + entryEdit.col5[1].props.children, accessor: "col6" });
        }

        if ("col6" in entryEdit) {
            columnsHeader.push({ Header: "Wed, " + entryEdit.col6[1].props.children, accessor: "col7" });
        }

        if ("col7" in entryEdit) {
            columnsHeader.push({ Header: "Thu, " + entryEdit.col7[1].props.children, accessor: "col8" });
        }

        if ("col8" in entryEdit) {
            columnsHeader.push({ Header: "Fri, " + entryEdit.col8[1].props.children, accessor: "col9" });
        }

        if ("col9" in entryEdit) {
            columnsHeader.push({ Header: "Sat, " + entryEdit.col9[1].props.children, accessor: "col10" });
        }

        if ("col10" in entryEdit) {
            columnsHeader.push({ Header: "Sun, " + entryEdit.col10[1].props.children, accessor: "col11" });
        }

        columnsHeader.push({ Header: "", accessor: "col12" });

        setHeader([...columnsHeader]);
        getEntriesByWeek({ week, setEntries, project, milestone, task, setLoading, setEntryNote, allEntries, handleDeleteEntries, dataStatus: dataEdit.status, changeHour, changeNote });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <Fragment>
            <div className="content-title content-title-entries">Entries</div>
            <div className="content-subtitle content-subtitle-entries">{entryEdit.col2 + " - " + entryEdit.col3}</div>
            <div className="content-table table-timesheet-entries">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {dataEdit.status !== "posted" && (
                <div className="content-add-new-entry">
                    <button onClick={handleAddNew}>
                        <IoAddOutline /> Add New Entry
                    </button>
                </div>
            )}
            {entryNote !== false && (
                <div className="content-popup popup-timesheet-entry">
                    <div className="content-popup-overlay"></div>
                    <div className="content-popup-container">
                        <div className="content-popup-header">
                            <span className="content-popup-title">Note</span>
                            <IoCloseOutline onClick={() => setEntryNote(false)} />
                        </div>
                        <div className="content-popup-body">
                            <textarea rows={5} defaultValue={entryNote ? entryNote.text : ""} disabled={dataEdit.status === "posted" ? true : false} onChange={(e) => changeNote(entryNote.id, entryNote.day, e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
            )}
            {entryNew && <TimesheetEntriesNew entryNew={entryNew} setEntryNew={setEntryNew} header={header} project={project} task={task} milestone={milestone} allEntries={allEntries} setAllEntries={setAllEntries} handleEntriesChange={handleEntriesChange} />}
        </Fragment>
    );
};

const TimesheetEntriesNew = ({ entryNew, setEntryNew, header, project, task, milestone, allEntries, setAllEntries, handleEntriesChange }) => {
    const [loading, setLoading] = useState(true);
    const [newData, setNewData] = useState([]);
    const [editNote, setEditNote] = useState(false);
    const textNoteRef = useRef();

    const columns = useMemo(() => header, [header]);
    const data = useMemo(() => newData, [newData]);

    const noteValue = editNote && entryNew.dates.find((r) => r.day === editNote) ? entryNew.dates.find((r) => r.day === editNote).note : "";

    const handleChangeEntry = (key, value) => {
        setEntryNew({ ...entryNew, [key]: parseInt(value) });
    };

    const handleChangeNote = () => {
        let hasDay = false;

        entryNew.dates.forEach((date) => {
            if (date.day === editNote) {
                date.note = textNoteRef.current.value;
                hasDay = true;
            }
        });

        if (!hasDay) {
            entryNew.dates.push({
                day: editNote,
                hour: 0,
                note: textNoteRef.current.value,
            });
        }

        setEntryNew({ ...entryNew });
    };

    const handleChangeDate = (day, hour) => {
        let hasDay = false;

        entryNew.dates.forEach((date) => {
            if (date.day === day) {
                date.hour = hour ? parseFloat(hour) : 0;
                hasDay = true;
            }
        });

        if (!hasDay) {
            entryNew.dates.push({
                day,
                hour: hour ? parseFloat(hour) : 0,
            });
        }

        setEntryNew({ ...entryNew });
    };

    const handleSave = () => {
        const entry = entryNew;

        if (findCol("col5") && !entry.dates.find((d) => d.day === "monday")) {
            entry.dates.push({ day: "monday", hour: 0 });
        }

        if (findCol("col6") && !entry.dates.find((d) => d.day === "tuesday")) {
            entry.dates.push({ day: "tuesday", hour: 0 });
        }

        if (findCol("col7") && !entry.dates.find((d) => d.day === "wednesday")) {
            entry.dates.push({ day: "wednesday", hour: 0 });
        }

        if (findCol("col8") && !entry.dates.find((d) => d.day === "thursday")) {
            entry.dates.push({ day: "thursday", hour: 0 });
        }

        if (findCol("col9") && !entry.dates.find((d) => d.day === "friday")) {
            entry.dates.push({ day: "friday", hour: 0 });
        }

        if (findCol("col10") && !entry.dates.find((d) => d.day === "saturday")) {
            entry.dates.push({ day: "saturday", hour: 0 });
        }

        if (findCol("col11") && !entry.dates.find((d) => d.day === "sunday")) {
            entry.dates.push({ day: "sunday", hour: 0 });
        }

        allEntries.push(entry);

        setAllEntries([...allEntries]);
        handleEntriesChange();
        setEntryNew(false);
    };

    const findCol = (col) => {
        let find = false;

        header.forEach((head) => {
            if (head.accessor === col) {
                find = true;
            }
        });

        return find;
    };

    useEffect(() => {
        const editableData = [
            {
                col1: <span>New</span>,
                col2: (
                    <select defaultValue={entryNew.project_id} onChange={(e) => handleChangeEntry("project_id", e.target.value)}>
                        {project.map((pro) => (
                            <option key={pro.id} value={pro.id}>
                                {pro.code + " - " + pro.name}
                            </option>
                        ))}
                    </select>
                ),
                col3: (
                    <select defaultValue={entryNew.milestone_id} onChange={(e) => handleChangeEntry("milestone_id", e.target.value)}>
                        {milestone.map((mile) => (
                            <option key={mile.id} value={mile.id}>
                                {mile.code + " - " + mile.name}
                            </option>
                        ))}
                    </select>
                ),
                col4: (
                    <select defaultValue={entryNew.task_id} onChange={(e) => handleChangeEntry("task_id", e.target.value)}>
                        {task.map((tsk) => (
                            <option key={tsk.id} value={tsk.id}>
                                {tsk.code + " - " + tsk.name}
                            </option>
                        ))}
                    </select>
                ),
                col12: (
                    <span className="table-actions">
                        <button onClick={() => handleSave()}>
                            <IoSaveOutline /> Save
                        </button>
                        <button onClick={() => setEntryNew(false)}>
                            <IoTrashOutline /> Cancel
                        </button>
                    </span>
                ),
            },
        ];

        if (findCol("col5")) {
            editableData[0].col5 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("monday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("monday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col6")) {
            editableData[0].col6 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("tuesday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("tuesday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col7")) {
            editableData[0].col7 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("wednesday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("wednesday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col8")) {
            editableData[0].col8 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("thursday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("thursday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col9")) {
            editableData[0].col9 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("friday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("friday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col10")) {
            editableData[0].col10 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("saturday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("saturday")} />
                    </span>
                </Fragment>
            );
        }

        if (findCol("col11")) {
            editableData[0].col11 = (
                <Fragment>
                    <span>
                        <input type="number" step="0.01" min="0.00" max="1000000.00" onChange={(e) => handleChangeDate("sunday", e.target.value)}></input> <BiPencil onClick={() => setEditNote("sunday")} />
                    </span>
                </Fragment>
            );
        }

        setNewData([...editableData]);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entryNew]);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <Fragment>
            <div className="content-table table-timesheet-new-entry">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {editNote && (
                <div className="content-popup popup-timesheet-new" key={editNote}>
                    <div className="content-popup-overlay"></div>
                    <div className="content-popup-container">
                        <div className="content-popup-header">
                            <span className="content-popup-title">Note</span>
                            <IoCloseOutline onClick={() => setEditNote(false)} />
                        </div>
                        <div className="content-popup-body">
                            <textarea ref={textNoteRef} rows={5} defaultValue={noteValue}></textarea>
                        </div>
                        <div className="content-popup-footer">
                            <button
                                onClick={() => {
                                    handleChangeNote();
                                    setEditNote(false);
                                }}
                            >
                                <IoSaveOutline /> Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Timesheet;
