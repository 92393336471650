import { useEffect, useState, useMemo, Fragment } from "react";
import { useTable } from "react-table";
import NavigationFull from "./Navigation/NavigationFull";
import { IoAddOutline, IoChevronDownOutline, IoSaveOutline, IoCloseOutline, IoTrashOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import useToken from "../App/useToken";
import axios from "axios";
import "./Dashboard.scss";

const getTask = ({ page, token, setTask, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDeleteTask }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/task?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];
                response.data.data.forEach((prj) => {
                    tableData.push({
                        col1: prj.code,
                        col2: prj.name,
                        col3: prj.status === "done" ? "Done" : "On Going",
                        col4: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        col5: <IoTrashOutline onClick={() => handleDeleteTask(prj.id)}></IoTrashOutline>,
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setTask([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getTask, props: { page, token, setTask, setCurrentPage, setLastPage, setLoading, setDataEdit, handleDeleteTask } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const updateTask = ({ dataEdit, handleGoToPage, notify, token, refreshToken, currentPage }) => {
    axios
        .put(process.env.REACT_APP_API_URL + "/task/" + dataEdit.id, dataEdit, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(currentPage);
                notify("success", "Success Save Data");
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: updateTask, props: { handleGoToPage, notify, currentPage, dataEdit, token } });
            } else {
                console.log(error);
                notify("error", "Data Not Saved");
            }
        });
};

const addTask = ({ dataEdit, handleGoToPage, notify, token, refreshToken, setDataEdit }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/task", dataEdit, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                handleGoToPage(1);
                setDataEdit({ ...dataEdit, id: response.data.data.id });
                notify("success", "Success Save Data");
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: addTask, props: { dataEdit, handleGoToPage, notify, token, setDataEdit } });
            }
        });
};

const deleteTask = ({ id, token, refreshToken, handleGoToPage, currentPage }) => {
    axios
        .delete(process.env.REACT_APP_API_URL + "/task/" + id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && response.data) {
                handleGoToPage(currentPage);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: deleteTask, props: { id, token, handleGoToPage, currentPage } });
            }
        });
};

const Task = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const { token, refreshToken } = useToken();

    const data = useMemo(() => task, [task]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Task Name",
                accessor: "col2",
            },
            {
                Header: "",
                accessor: "col4",
            },
            {
                Header: "",
                accessor: "col5",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getTask({ page: 1, token, setTask, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDeleteTask });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getTask({ page, token, setTask, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, handleDeleteTask });
    };

    const handleDeleteTask = (id) => {
        setDataEdit(false);
        deleteTask({ id, token, refreshToken, handleGoToPage, currentPage });
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                <div className="content-title">
                    <span>Tasks</span>
                </div>
                <div className="content-add-new">
                    <button className="add-new-task" onClick={() => setDataEdit({})}>
                        <IoAddOutline></IoAddOutline>New Task
                    </button>
                </div>
                <div className="content-table table-task">
                    {!loading && (
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="content-pagination">
                    <span className="page-information">
                        Page {currentPage} of {lastPage}
                    </span>
                    <div className="page-actions">
                        {currentPage > 1 && (
                            <Fragment>
                                <div className="select-first" onClick={() => handleGoToPage(1)}>
                                    <BiChevronsLeft />
                                </div>
                                <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                    <BiChevronLeft />
                                </div>
                            </Fragment>
                        )}
                        {lastPage > 1 && (
                            <div className="select-page">
                                <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                            </div>
                        )}
                        {currentPage < lastPage && (
                            <Fragment>
                                <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                    <BiChevronRight />
                                </div>
                                <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                    <BiChevronsRight />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
                {dataEdit && <TaskEdit dataEdit={dataEdit} setDataEdit={setDataEdit} token={token} handleGoToPage={handleGoToPage} currentPage={currentPage} notify={notify} refreshToken={refreshToken} />}
            </section>
        </NavigationFull>
    );
};

const TaskEdit = ({ dataEdit, setDataEdit, token, handleGoToPage, currentPage, notify, refreshToken }) => {
    const handleChangeEdit = (key, value) => {
        setDataEdit({ ...dataEdit, [key]: value });
    };

    const handleSaveEdit = () => {
        if (dataEdit.id) {
            updateTask({ handleGoToPage, notify, currentPage, refreshToken, dataEdit, token });
        } else {
            addTask({ handleGoToPage, notify, refreshToken, dataEdit, token, setDataEdit });
        }
    };

    return (
        <div className="content-edit" key={dataEdit.id}>
            <div className="content-edit-title">
                <span>{dataEdit.id ? "Edit Task " + dataEdit.code : "New Task " + (dataEdit.code ? dataEdit.code : "")}</span>
            </div>
            <div className="data-edit-content">
                <span className="task-code-edit">
                    <label>Task Number</label>
                    <input type="text" defaultValue={dataEdit.code} onChange={(e) => handleChangeEdit("code", e.target.value)}></input>
                </span>
                <span className="task-code-name">
                    <label>Task Name</label>
                    <input type="text" defaultValue={dataEdit.name} onChange={(e) => handleChangeEdit("name", e.target.value)}></input>
                </span>
            </div>
            <div className="data-edit-actions">
                <button className="data-edit-close" onClick={() => setDataEdit(false)}>
                    <IoCloseOutline /> Close
                </button>
                <button className="data-edit-save" onClick={handleSaveEdit}>
                    <IoSaveOutline /> Save
                </button>
            </div>
        </div>
    );
};

export default Task;
