import { useState, useEffect, Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Timesheet from "./components/Dashboard/Timesheet";
import Employee from "./components/Dashboard/Employee";
import EmployeeTimesheet from "./components/Dashboard/EmployeeTimesheet";
import Reimbursement from "./components/Dashboard/Reimbursement";
import EmployeeReimbursement from "./components/Dashboard/EmployeeReimbursement";
import Task from "./components/Dashboard/Task";
import Project from "./components/Dashboard/Project";
import Phase from "./components/Dashboard/Phase";
import Login from "./components/Login/Login";
import Profile from "./components/Dashboard/Profile";
import useToken from "./components/App/useToken";
import useUser from "./components/App/useUser";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";

const checkAuthenticated = ({ setUser, setIsAuthenticated, setLoading, token, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/auth/profile", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null && response.data.user) {
                setUser(response.data.user);
                setIsAuthenticated(true);
                setLoading(false);
            } else {
                if (refreshToken) {
                    refreshToken({ callback: checkAuthenticated, props: { setUser, setIsAuthenticated, setLoading, token } });
                } else {
                    setLoading(false);
                }
            }
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
};

const App = () => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { token, refreshToken, setToken } = useToken();
    const { setUser } = useUser();

    useEffect(() => {
        if (typeof token === "object" && !Array.isArray(token) && token !== null) {
            checkAuthenticated({ setUser, setIsAuthenticated, setLoading, token, refreshToken });
        } else {
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const notify = (type, message) => toast[type](message);

    return loading ? (
        <div className="loader">Loading...</div>
    ) : isAuthenticated ? (
        <Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Dashboard />}></Route>
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/timesheet" element={<Timesheet notify={notify} />}></Route>
                    <Route path="/reimbursement" element={<Reimbursement notify={notify} />}></Route>
                    <Route path="/employee" element={<Employee notify={notify} />}></Route>
                    <Route path="/employee/:id/timesheet" element={<EmployeeTimesheet notify={notify} />}></Route>
                    <Route path="/employee/:id/reimbursement" element={<EmployeeReimbursement notify={notify} />}></Route>
                    <Route path="/task" element={<Task notify={notify} />}></Route>
                    <Route path="/project" element={<Project notify={notify} />}></Route>
                    <Route path="/phase" element={<Phase notify={notify} />}></Route>
                    <Route path="/profile" element={<Profile notify={notify} />}></Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </Fragment>
    ) : (
        <Login setToken={setToken} setUser={setUser} />
    );
};

export default App;
