import { useState } from "react";
import axios from "axios";
import { ReactSVG } from "react-svg";
import "./Login.scss";

async function loginUser({ email, password }) {
    return axios.post(process.env.REACT_APP_API_URL + "/auth/login", {
        email,
        password,
    });
}

const Login = ({ setToken, setUser }) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [loginValid, setLoginValid] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoginValid(true);

        const token = loginUser({
            email,
            password,
        });

        token
            .then((response) => {
                if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                    console.log(response.data)
                    setToken(response.data);
                    setUser(response.data.user)
                    window.location.reload();
                }

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setLoginValid(false);
            });
    };

    return (
        <div className="login-wrapper">
            <div className="login-image"></div>
            <div className="login-form">
                <form onSubmit={handleSubmit}>
                    <img src="logo.png" alt="Logo" />
                    <label>
                        <p>Email</p>
                        <input type="text" onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label>
                        <p>Password</p>
                        <input type="password" onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    {!loginValid && <span className="login-notice">Wrong Email or Password</span>}
                    <div>
                        <button type="submit">
                            {loading ? (
                                <ReactSVG src="loading.svg" />
                            ) : (
                                <span>
                                    Log In <ReactSVG src="icons/arrow-right.svg" />
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
