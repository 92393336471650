import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import { useTable } from "react-table";
import { useParams } from "react-router-dom";
import { IoChevronDownOutline, IoCloseOutline, IoPrintOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight, BiChevronLeft, BiChevronRight, BiPencil } from "react-icons/bi";
import { ReactSVG } from "react-svg";
import useToken from "../App/useToken";
import useDate from "../App/useDate";
import NavigationFull from "./Navigation/NavigationFull";
import PopupConfirmation from "./Popup/PopupConfirmation";
import TimesheetPrint from "./TimesheetPrint";
import ReactToPrint from "react-to-print";
import axios from "axios";
import "./Dashboard.scss";

const getTimesheet = ({ page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken, userID }) => {
    const statusMap = {
        draft: "Draft",
        posted: "Posted",
        submitted: "Submitted",
    };

    const countHours = (project) => {
        let hours = 0;

        if (Array.isArray(project.entries)) {
            project.entries.forEach((entry) => {
                if (Array.isArray(entry.dates)) {
                    entry.dates.forEach((date) => {
                        hours += typeof date.hour === "number" && !isNaN(date.hour) ? date.hour : 0;
                    });
                }
            });
        }

        return hours;
    };

    axios
        .get(process.env.REACT_APP_API_URL + "/timesheet/user/" + userID + "?page=" + page, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                const tableData = [];
                response.data.data.forEach((prj) => {
                    tableData.push({
                        col1: prj.number,
                        col2: <span className={"status-" + prj.status}>{statusMap[prj.status]}</span>,
                        col3: prj.start_date,
                        col4: prj.end_date,
                        col5: Math.round(countHours(prj) * 100) / 100,
                        col6: <IoChevronDownOutline onClick={() => setDataEdit(prj)}></IoChevronDownOutline>,
                        data: prj,
                    });
                });

                setCurrentPage(response.data.current_page);
                setLastPage(Math.ceil(response.data.total / response.data.per_page));
                setTimesheet([...tableData]);
            }

            setLoading(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getTimesheet, props: { page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, userID } });
            } else {
                console.log(error);
                setLoading(false);
            }
        });
};

const getUser = ({ token, setUser, userID, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/user/" + userID, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setUser({ ...response.data.data });
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getUser, props: { token, userID } });
            } else {
                console.log(error);
            }
        });
};

const getProject = ({ token, setProject, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/project/ongoing", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setProject([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getProject, props: { token, setProject } });
            } else {
                console.log(error);
            }
        });
};

const getTask = ({ token, setTask, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/task/all", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setTask([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getTask, props: { token, setTask } });
            } else {
                console.log(error);
            }
        });
};

const getMilestone = ({ token, setMilestone, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/milestone/all", {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                setMilestone([...response.data.data]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getMilestone, props: { token, setMilestone } });
            } else {
                console.log(error);
            }
        });
};

const getEntriesByWeek = ({ week, token, setEntries, project, milestone, task, setLoading, handleEntriesWeekChange, refreshToken, setEntryNote, allEntries }) => {
    const entries = [];
    const weekEntries = allEntries.filter((r) => r.week === week);

    weekEntries.forEach((data) => {
        const projectOption = project.find((r) => r.id === data.project_id);
        const milestoneOption = milestone.find((r) => r.id === data.milestone_id);
        const taskOption = task.find((r) => r.id === data.task_id);

        const entryObj = {
            col1: <span>Draft</span>,
            col2: projectOption ? projectOption.name : "",
            col3: milestoneOption ? milestoneOption.name : "",
            col4: taskOption ? taskOption.name : "",
        };

        if (Array.isArray(data.dates)) {
            data.dates.forEach((date) => {
                if (date.day === "monday") {
                    entryObj.col5 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "tuesday") {
                    entryObj.col6 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "wednesday") {
                    entryObj.col7 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "thursday") {
                    entryObj.col8 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "friday") {
                    entryObj.col9 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "saturday") {
                    entryObj.col10 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }

                if (date.day === "sunday") {
                    entryObj.col11 = (
                        <Fragment>
                            {date.hour} <BiPencil className={date.note ? "active" : ""} onClick={() => setEntryNote(date.note)} />
                        </Fragment>
                    );
                }
            });
        }

        entries.push(entryObj);
    });

    setEntries([...entries]);
    setLoading(false);
};

const getEntries = ({ allEntries, dataEdit, getDates, formatDate }) => {
    const countHours = (entries, day) => {
        let hours = 0;

        entries.forEach((entry) => {
            if (Array.isArray(entry.dates)) {
                entry.dates.forEach((date) => {
                    if (date.day === day) {
                        hours += date.hour;
                    }
                });
            }
        });

        return hours;
    };

    const listDates = getDates(dataEdit.start_date, dataEdit.end_date);
    const periodList = [];

    let columns = {};
    let week = 1;
    let weekHour = 0;
    let dayHour = 0;
    let periodHour = 0;
    let startWeek = formatDate(listDates[0]);
    let endWeek = formatDate(listDates[0]);

    listDates.forEach((date) => {
        const d = new Date(date);
        let weekEntries = allEntries.filter((r) => r.week === week && r.status !== "deleted");

        switch (d.getDay()) {
            case 0:
                // Sunday
                dayHour = countHours(weekEntries, "sunday");
                weekHour += dayHour;
                columns["col10"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 1:
                // Monday
                if (Object.entries(columns).length > 0) {
                    columns["col1"] = week;
                    columns["col2"] = formatDate(startWeek);
                    columns["col3"] = formatDate(endWeek);
                    columns["col11"] = weekHour;

                    periodHour += weekHour;
                    week++;
                    weekHour = 0;
                    startWeek = formatDate(date);
                    endWeek = formatDate(date);

                    periodList.push(columns);
                    weekEntries = allEntries.filter((r) => r.week === week);
                }

                columns = {};
                dayHour = countHours(weekEntries, "monday");
                weekHour += dayHour;
                columns["col4"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 2:
                // Tuesday
                dayHour = countHours(weekEntries, "tuesday");
                weekHour += dayHour;
                columns["col5"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 3:
                // Wednesday
                dayHour = countHours(weekEntries, "wednesday");
                weekHour += dayHour;
                columns["col6"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 4:
                // Thursday
                dayHour = countHours(weekEntries, "thursday");
                weekHour += dayHour;
                columns["col7"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 5:
                // Friday
                dayHour = countHours(weekEntries, "friday");
                weekHour += dayHour;
                columns["col8"] = [countHours(weekEntries, "friday"), formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
            case 6:
                // Saturday
                dayHour = countHours(weekEntries, "saturday");
                weekHour += dayHour;
                columns["col9"] = [dayHour, formatDate(date)].map((line, index) => <div key={index}>{line}</div>);
                break;
        }

        endWeek = date;
    });

    if (Object.entries(columns).length > 0) {
        columns["col1"] = week;
        columns["col2"] = formatDate(startWeek);
        columns["col3"] = formatDate(endWeek);
        columns["col11"] = Math.round(weekHour * 100) / 100;

        periodHour += weekHour;
        periodList.push(columns);
    }

    periodList.push({
        col10: "Total",
        col11: Math.round(periodHour * 100) / 100,
    });

    return periodList;
};

const getAllEntries = ({ token, setAllEntries, dataEdit, refreshToken }) => {
    axios
        .get(process.env.REACT_APP_API_URL + "/timesheet-entry/timesheet/" + dataEdit.id, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && (response.data !== null) & Array.isArray(response.data.data)) {
                const allEntries = response.data.data;

                allEntries.forEach((entry) => {
                    entry.status = "saved";
                });

                setAllEntries([...allEntries]);
            }
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: getAllEntries, props: { token, setAllEntries, refreshToken } });
            } else {
                console.log(error);
            }
        });
};

const postedTimesheet = ({ token, setIsLoadingPosted, notify, refreshToken, dataEdit, setDataEdit, user }) => {
    axios
        .post(
            process.env.REACT_APP_API_URL + "/timesheet/" + dataEdit.id + "/posted",
            { sallary: user.sallary },
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token.token}`,
                },
            }
        )
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                notify("success", "Success Approve Timesheet");
                setDataEdit({ ...dataEdit, status: "posted" });
            } else {
                notify("error", "Failed Approve Timesheet");
            }

            setIsLoadingPosted(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: postedTimesheet, props: { token, setIsLoadingPosted, notify, dataEdit, setDataEdit, user } });
            } else {
                notify("error", "Failed Approve Timesheet");
                setIsLoadingPosted(false);
                console.log(error);
            }
        });
};

const unpostedTimesheet = ({ token, setIsLoadingUnposted, notify, refreshToken, dataEdit, setDataEdit }) => {
    axios
        .post(process.env.REACT_APP_API_URL + "/timesheet/" + dataEdit.id + "/unposted", null, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token.token}`,
            },
        })
        .then((response) => {
            if (response !== undefined && typeof response.data === "object" && !Array.isArray(response.data) && response.data !== null) {
                notify("success", "Success Unapprove Timesheet");
                setDataEdit({ ...dataEdit, status: "submitted" });
            } else {
                notify("error", "Failed Unapprove Timesheet");
            }

            setIsLoadingUnposted(false);
        })
        .catch((error) => {
            if (refreshToken) {
                refreshToken({ callback: postedTimesheet, props: { token, setIsLoadingUnposted, notify, dataEdit, setDataEdit } });
            } else {
                notify("error", "Failed Unapprove Timesheet");
                setIsLoadingUnposted(false);
                console.log(error);
            }
        });
};

const EmployeeTimesheet = ({ notify }) => {
    const [loading, setLoading] = useState(true);
    const [timesheet, setTimesheet] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [dataEdit, setDataEdit] = useState(false);
    const [project, setProject] = useState([]);
    const [task, setTask] = useState([]);
    const [milestone, setMilestone] = useState([]);
    const [user, setUser] = useState({});
    const { token, refreshToken } = useToken();
    const { getDates, formatDate } = useDate();
    const { id } = useParams();

    const data = useMemo(() => timesheet, [timesheet]);
    const columns = useMemo(
        () => [
            {
                Header: "Number",
                accessor: "col1",
            },
            {
                Header: "Status",
                accessor: "col2",
            },
            {
                Header: "Start Date",
                accessor: "col3",
            },
            {
                Header: "End Date",
                accessor: "col4",
            },
            {
                Header: "Hours",
                accessor: "col5",
            },
            {
                Header: "",
                accessor: "col6",
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    useEffect(() => {
        getTimesheet({ page: 1, userID: id, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken });
        getProject({ token, setProject, refreshToken });
        getTask({ token, setTask, refreshToken });
        getMilestone({ token, setMilestone, refreshToken });
        getUser({ token, refreshToken, setUser, userID: id });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoToPage = (page) => {
        getTimesheet({ page, token, setTimesheet, setCurrentPage, setLastPage, setLoading, setDataEdit, refreshToken });
    };

    return (
        <NavigationFull>
            <section className="dashboard-content-body">
                {dataEdit ? (
                    <TimesheetPeriod dataEdit={dataEdit} setDataEdit={setDataEdit} project={project} task={task} milestone={milestone} token={token} refreshToken={refreshToken} notify={notify} user={user} userID={id} getDates={getDates} formatDate={formatDate} />
                ) : (
                    <Fragment>
                        <div className="content-title">
                            <span>{user.name}'s Timesheets</span>
                        </div>
                        <div className="content-table table-timesheet">
                            {!loading && (
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps()} key={index}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={index} onClick={() => setDataEdit(row.original.data)}>
                                                    {row.cells.map((cell, index) => {
                                                        return (
                                                            <td {...cell.getCellProps()} key={index}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="content-pagination">
                            <span className="page-information">
                                Page {currentPage} of {lastPage}
                            </span>
                            <div className="page-actions">
                                {currentPage > 1 && (
                                    <Fragment>
                                        <div className="select-first" onClick={() => handleGoToPage(1)}>
                                            <BiChevronsLeft />
                                        </div>
                                        <div className="select-prev" onClick={() => handleGoToPage(currentPage - 1)}>
                                            <BiChevronLeft />
                                        </div>
                                    </Fragment>
                                )}
                                {lastPage > 1 && (
                                    <div className="select-page">
                                        <input type="number" onChange={(e) => handleGoToPage(e.target.value)} />
                                    </div>
                                )}
                                {currentPage < lastPage && (
                                    <Fragment>
                                        <div className="select-next" onClick={() => handleGoToPage(currentPage + 1)}>
                                            <BiChevronRight />
                                        </div>
                                        <div className="select-last" onClick={() => handleGoToPage(lastPage)}>
                                            <BiChevronsRight />
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </section>
        </NavigationFull>
    );
};

const TimesheetPeriod = ({ dataEdit, setDataEdit, project, task, milestone, token, refreshToken, notify, user, userID, getDates, formatDate }) => {
    const [loading, setLoading] = useState(true);
    const [rowActive, setRowActive] = useState(-1);
    const [entryEdit, setEntryEdit] = useState(false);
    const [isLoadingPosted, setIsLoadingPosted] = useState(false);
    const [isLoadingUnposted, setIsLoadingUnposted] = useState(false);
    const [allEntries, setAllEntries] = useState([]);
    const [postedConfirmation, setPostedConfirmation] = useState(false);
    const [unpostedConfirmation, setUnpostedConfirmation] = useState(false);

    let printComponentRef = useRef();
    const period = getEntries({ dataEdit, allEntries, getDates, formatDate });

    const data = useMemo(() => period, [period]);
    const columns = useMemo(
        () => [
            {
                Header: "Week",
                accessor: "col1",
            },
            {
                Header: "Start",
                accessor: "col2",
            },
            {
                Header: "End",
                accessor: "col3",
            },
            {
                Header: "Mon",
                accessor: "col4",
            },
            {
                Header: "Tue",
                accessor: "col5",
            },
            {
                Header: "Wed",
                accessor: "col6",
            },
            {
                Header: "Thu",
                accessor: "col7",
            },
            {
                Header: "Fri",
                accessor: "col8",
            },
            {
                Header: "Sat",
                accessor: "col9",
            },
            {
                Header: "Sun",
                accessor: "col10",
            },
            {
                Header: "Hours",
                accessor: "col11",
            },
        ],
        []
    );

    useEffect(() => {
        setLoading(false);
        getAllEntries({ token, setAllEntries, dataEdit, refreshToken });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    const handleRowClick = (row) => {
        if (row.index < period.length - 1) {
            setRowActive(row.index);
            setEntryEdit(period[row.index]);
        }
    };

    const handleEntriesChange = () => {
        getEntries({ dataEdit, allEntries, getDates, formatDate });
    };

    const handlePostedButton = () => {
        if (dataEdit.status !== "posted") {
            setPostedConfirmation(true);
        }
    };

    const handlePosted = () => {
        if (dataEdit.status !== "posted") {
            setIsLoadingPosted(true);
            postedTimesheet({ token, setIsLoadingPosted, notify, refreshToken, dataEdit, setDataEdit, user });
            setPostedConfirmation(false);
        }
    };

    const handleUnpostedButton = () => {
        if (dataEdit.status === "posted") {
            setUnpostedConfirmation(true);
        }
    };

    const handleUnposted = () => {
        if (dataEdit.status === "posted") {
            setIsLoadingUnposted(true);
            unpostedTimesheet({ token, setIsLoadingUnposted, notify, refreshToken, dataEdit, setDataEdit });
            setUnpostedConfirmation(false);
        }
    };

    const getSallary = () => {
        let sallary = 0;

        const formatter = new Intl.NumberFormat("id", {
            style: "currency",
            currency: "IDR",
        });

        if (period.length > 0) {
            if (dataEdit.status === "posted" && Number.isInteger(dataEdit.sallary)) {
                sallary = dataEdit.sallary * period[period.length - 1]["col11"];
            } else if (Number.isInteger(user.sallary)) {
                sallary = user.sallary * period[period.length - 1]["col11"];
            }
        }

        return formatter.format(sallary);
    };

    return (
        <Fragment>
            <div className="content-title">
                <span>
                    <span className="content-title-main">{user.name}'s Timesheet</span>
                    <span className="content-title-sub">Period Ending {formatDate(dataEdit.end_date)}</span>
                    {user.sallary && <span className="content-title-sub">Period Sallary: {getSallary()}</span>}
                </span>
                <span className="content-actions">
                    <button className="content-action-back" onClick={() => (window.location.href = process.env.REACT_APP_HOME_URL + "/employee/" + userID + "/timesheet")}>
                        <IoCloseOutline /> Close
                    </button>
                    <button className={dataEdit.status === "posted" ? "content-action-approve posted" : "content-action-approve"} onClick={handlePostedButton}>
                        {isLoadingPosted ? (
                            <ReactSVG className="loading-svg" src="loading.svg" />
                        ) : (
                            <Fragment>
                                <IoCheckmarkDoneOutline /> Approve
                            </Fragment>
                        )}
                    </button>
                    {dataEdit.status === "posted" && (
                        <button className="content-action-approve" onClick={handleUnpostedButton}>
                            {isLoadingUnposted ? (
                                <ReactSVG className="loading-svg" src="loading.svg" />
                            ) : (
                                <Fragment>
                                    <IoCloseOutline /> Unapprove
                                </Fragment>
                            )}
                        </button>
                    )}
                    <ReactToPrint
                        trigger={() => (
                            <button className="content-action-print">
                                <IoPrintOutline /> Print
                            </button>
                        )}
                        content={() => printComponentRef}
                    />
                </span>
            </div>
            <div className="content-table table-timesheet-period">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={() => handleRowClick(row)} className={index === rowActive ? "active" : ""} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {entryEdit && <TimesheetEntries dataEdit={entryEdit} key={entryEdit.col1} timesheetID={dataEdit.id} project={project} task={task} milestone={milestone} week={rowActive + 1} token={token} handleEntriesChange={handleEntriesChange} refreshToken={refreshToken} allEntries={allEntries} />}
            {postedConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setPostedConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handlePosted();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Approve timesheet?</p>
                </PopupConfirmation>
            )}
            {unpostedConfirmation && (
                <PopupConfirmation
                    cancelAction={() => setUnpostedConfirmation(false)}
                    footerAction={
                        <button
                            onClick={() => {
                                handleUnposted();
                            }}
                        >
                            Continue
                        </button>
                    }
                >
                    <p className="">Unapprove timesheet?</p>
                </PopupConfirmation>
            )}
            <TimesheetPrint printRef={(el) => (printComponentRef = el)} user={user} dataEdit={dataEdit} sallary={getSallary()} allEntries={allEntries} project={project} task={task} milestone={milestone} />
        </Fragment>
    );
};

const TimesheetEntries = ({ dataEdit, timesheetID, project, task, milestone, week, token, handleEntriesChange, refreshToken, allEntries }) => {
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState([]);
    const [entryNote, setEntryNote] = useState(false);

    const data = useMemo(() => entries, [entries]);
    const columns = useMemo(() => header, [header]);

    const handleEntriesWeekChange = () => {
        getEntriesByWeek({ week, token, setEntries, project, milestone, task, handleEntriesWeekChange, setLoading, refreshToken, setEntryNote, allEntries });
        handleEntriesChange();
    };

    useEffect(() => {
        const columnsHeader = [
            {
                Header: "Status",
                accessor: "col1",
            },
            {
                Header: "Project",
                accessor: "col2",
            },
            {
                Header: "Milestone / Phase",
                accessor: "col3",
            },
            {
                Header: "Task",
                accessor: "col4",
            },
        ];

        if ("col4" in dataEdit) {
            columnsHeader.push({ Header: "Mon, " + dataEdit.col4[1].props.children, accessor: "col5" });
        }

        if ("col5" in dataEdit) {
            columnsHeader.push({ Header: "Tue, " + dataEdit.col5[1].props.children, accessor: "col6" });
        }

        if ("col6" in dataEdit) {
            columnsHeader.push({ Header: "Wed, " + dataEdit.col6[1].props.children, accessor: "col7" });
        }

        if ("col7" in dataEdit) {
            columnsHeader.push({ Header: "Thu, " + dataEdit.col7[1].props.children, accessor: "col8" });
        }

        if ("col8" in dataEdit) {
            columnsHeader.push({ Header: "Fri, " + dataEdit.col8[1].props.children, accessor: "col9" });
        }

        if ("col9" in dataEdit) {
            columnsHeader.push({ Header: "Sat, " + dataEdit.col9[1].props.children, accessor: "col10" });
        }

        if ("col10" in dataEdit) {
            columnsHeader.push({ Header: "Sun, " + dataEdit.col10[1].props.children, accessor: "col11" });
        }

        setHeader([...columnsHeader]);
        getEntriesByWeek({ week, token, setEntries, project, milestone, task, setLoading, handleEntriesWeekChange, refreshToken, setEntryNote, allEntries });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <Fragment>
            <div className="content-title content-title-entries">Entries</div>
            <div className="content-subtitle content-subtitle-entries">{dataEdit.col2 + " - " + dataEdit.col3}</div>
            <div className="content-table table-timesheet-entries">
                {!loading && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th {...column.getHeaderProps()} key={index}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td {...cell.getCellProps()} key={index}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {entryNote !== false && (
                <div className="content-popup popup-timesheet-entry">
                    <div className="content-popup-overlay"></div>
                    <div className="content-popup-container">
                        <div className="content-popup-header">
                            <span className="content-popup-title">Note</span>
                            <IoCloseOutline onClick={() => setEntryNote(false)} />
                        </div>
                        <div className="content-popup-body">
                            <textarea rows={5} value={entryNote ? entryNote : ""} disabled={true}></textarea>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default EmployeeTimesheet;
