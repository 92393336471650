import { IoMenuOutline } from "react-icons/io5";
import UserInfo from "./UserInfo";

const HeaderFull = ({ handleSidebar, sidebarButtonRef }) => {
    return (
        <header className="dashboard-header">
            <div className="header-wrapper">
                <button ref={sidebarButtonRef} className="navigation-button" aria-label="Menu" onClick={handleSidebar}>
                    <IoMenuOutline style={{ width: "1.5rem", height: "1.5rem" }} />
                </button>
                <span></span>
                <UserInfo />
            </div>
        </header>
    );
};

export default HeaderFull;
