import useNavigation from "../../App/useNavigation";
import { useLocation } from "react-router-dom";

const Sidebar = ({ sidebarActive, sidebarRef }) => {
    const { navigation } = useNavigation();
    const location = useLocation();

    return (
        <aside ref={sidebarRef} className={sidebarActive ? "side-nav show-sidebar" : "side-nav"}>
            <div className="navigation-wrapper">
                <a className="logo-link" href="/dashboard">
                    <img src={process.env.REACT_APP_HOME_URL + "/logo.png"} alt="ucds logo" width="135" className="dashboard-logo" />
                </a>
                <ul className="navigation-lists">
                    {navigation.map((nav) => (
                        <li key={nav.id} className={location.pathname.includes(nav.id) ? "navigation-item active" : "navigation-item"}>
                            <a className="navigation-link" href={process.env.REACT_APP_HOME_URL + '/'+nav.id}>
                                <span className="navigation-border"></span>
                                {nav.icon}
                                <span className="navigation-text">{nav.text}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;
